import { Injectable } from '@angular/core';
import {
  BluetoothLE,
  ScanStatus,
  WriteCharacteristicParams,
} from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface IBluetoothLeDevice {
  id: string;
  uuid: string;
  address: string;
  class: number;
  name: string;
  rssi: number;
  characteristic: string;
  service: string;
}

export interface IConnectedError {
  error: string;
  message: string;
  address: string;
}

@Injectable({ providedIn: 'root' })
export class BluetoothLeService {
  constructor(private bluetoothLE: BluetoothLE) {}

  startScan(): Observable<IBluetoothLeDevice> {
    this.bluetoothLE.enable();

    return this.bluetoothLE.initialize().pipe(
      //   switchMap(() => {
      //     return from(this.bluetoothLE.requestPermissionBtScan()).pipe(
      //       switchMap((hasPerm) => {
      //         if (hasPerm) {
      //           return of(true);
      //         } else {
      //           return from(this.bluetoothLE.requestPermissionBtScan());
      //         }
      //       })
      //     );
      //   }),
      switchMap(() => {
        return this.bluetoothLE
          .startScan({
            allowDuplicates: true,
            scanMode: this.bluetoothLE.SCAN_MODE_LOW_LATENCY,
            matchMode: this.bluetoothLE.MATCH_MODE_AGGRESSIVE,
            matchNum: this.bluetoothLE.MATCH_NUM_MAX_ADVERTISEMENT,
            callbackType: this.bluetoothLE.CALLBACK_TYPE_ALL_MATCHES,
          })
          .pipe(
            map((res: ScanStatus) => {
              if (res.status != 'scanResult') {
                return null;
              }

              const advertisement: any = {};

              if (
                typeof res.advertisement == 'object' &&
                res.advertisement.serviceUuids &&
                res.advertisement.serviceUuids.length
              ) {
                advertisement.service = res.advertisement.serviceUuids[0];
              }

              console.log('SCAN RESULT');
              console.log(JSON.stringify(res));

              return <IBluetoothLeDevice>{
                uuid: res.address,
                address: res.address,
                name: res.name,
                rssi: res.rssi,
                service: advertisement.service,
                // characteristic: res.characteristic,
              };
            })
          );
      })
    );
  }

  stopScan(): Promise<any> {
    return this.bluetoothLE.stopScan();
  }

  async sendDataToDevice(device: any, data: Uint8Array): Promise<any> {
    // const hasPermResp = await this.bluetoothLE.hasPermissionBtConnect();
    // if (!hasPermResp.hasPermission) {
    //   const requestPerm = await this.bluetoothLE.requestPermissionBtConnect();
    //   if (!requestPerm.requestPermission) {
    //     throw 'Bluetooth conection rejected.';
    //   }
    // }

    this.bluetoothLE.enable();

    await this._initialize();
    await this.bluetoothLE
      .isConnected({
        address: device.address,
      })
      .catch((err: IConnectedError) => {
        return this._connect(device.address);
      });

    const services = await this.bluetoothLE.services({
      address: device.address,
    });

    const characteristic = await this.bluetoothLE.characteristics({
      address: device.address,
      service: services.services[0],
    });
    const testChar = characteristic.characteristics.find(
      (c) => c.properties.writeWithoutResponse || c.properties.write
    );

    var encodedUnicodeString = this.bluetoothLE.bytesToEncodedString(data);
    const params: WriteCharacteristicParams = {
      value: encodedUnicodeString,
      service: characteristic.service,
      characteristic: testChar.uuid,
      address: device.address,
    };

    await this.bluetoothLE.write(params);
    await this.bluetoothLE.disconnect({
      address: device.address,
    });
    await this.bluetoothLE.close({
      address: device.address,
    });
  }

  private _initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bluetoothLE
        .initialize()
        .subscribe((res: { status: 'enabled' | 'disabled' }) => {
          if (res.status == 'enabled') {
            resolve();
          } else {
            reject(' Помилка активації Bluetooth');
          }
        });
    });
  }

  private _connect(address: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bluetoothLE
        .connect({
          address: address,
        })
        .subscribe((res) => {
          if (res.status == 'connected') {
            resolve();
          } else {
            reject(' Помилка підключення до пристрою');
          }
        });
    });
  }
}
