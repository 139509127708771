import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import * as QRCode from 'qrcode';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  templateUrl: './check.component.html',
  selector: 'app-check-preview',
})
export class CheckComponent implements OnInit {
  printText: string;
  qrCodeData: string;
  qrCodeDataUrl: string;
  paymentQrCodeDataUrl: string;
  qrCodePayment: { msgBefore: string; msgAfter: string; url: string };
  checkUuid: string;
  htmlString: string;
  html: SafeHtml;

  private _logoDataUrl: string;
  @Input() set logoDataUrl(dataUrl: string) {
    if (dataUrl) {
      this._logoDataUrl = 'data:' + dataUrl;
    }
  }
  get logoDataUrl(): string {
    return this._logoDataUrl;
  }

  constructor(
    private modalController: ModalController,
    private shared: SharedService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    if (this.htmlString) {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
    }
    if (this.qrCodeData) {
      this.qrCodeDataUrl = await QRCode.toDataURL(this.qrCodeData, {
        type: 'terminal',
        width: '120',
      });
    }

    if (this.qrCodePayment && this.qrCodePayment.url) {
      this.paymentQrCodeDataUrl = await QRCode.toDataURL(
        this.qrCodePayment.url,
        {
          type: 'terminal',
          width: '120',
        }
      );
    }
  }

  close() {
    this.modalController.dismiss();
  }

  sendOrder() {
    this.shared.sendEmailDialog(this.checkUuid);
  }
}
