<ion-header>
  <ion-toolbar>
    <ion-title>Відправити на пошту</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" color="dark">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-input
      ngModel
      [disabled]="loading"
      [formControl]="emailControl"
      placeholder="Вкажіть електронну адресу"
      type="email"
    ></ion-input>
    <ion-note slot="error">Ви ввели не корректну пошту</ion-note>
    <ion-note slot="helper">Вкажіть електронну адресу для відправки</ion-note>
  </ion-item>
  <ion-item lines="none">
    <ion-button
      slot="end"
      color="dark"
      fill="outline"
      shape="round"
      size="default"
      (click)="send()"
      [disabled]="loading"
    >
      <ion-icon slot="start" name="send-outline"></ion-icon>
      Відправити
      <span class="bg-loader ml-2" *ngIf="loading"></span>
    </ion-button>
  </ion-item>
</ion-content>
