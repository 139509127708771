<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        color="dark"
        (click)="sendOrder()"
        class="mr-2"
        *ngIf="checkUuid"
      >
        <ion-icon name="send-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="close()" color="dark">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="text-center" *ngIf="logoDataUrl">
    <img width="120" [src]="logoDataUrl" style="margin: auto" />
  </div>
  <div class="text-center" *ngIf="printText">
    <pre class="m-0">{{ printText }}</pre>
  </div>
  <div class="text-center" *ngIf="qrCodeDataUrl">
    <img [src]="qrCodeDataUrl" style="margin: auto" />
  </div>
  <div class="text-center" *ngIf="qrCodeDataUrl">TurboPOS</div>
  <div *ngIf="qrCodePayment">
    <div class="text-center">
      {{ qrCodePayment.msgBefore }}
    </div>
    <div class="text-center" *ngIf="paymentQrCodeDataUrl">
      <img [src]="paymentQrCodeDataUrl" style="margin: auto" />
    </div>
    <div class="text-center">
      {{ qrCodePayment.msgAfter }}
    </div>
  </div>
  <div *ngIf="html" [innerHTML]="html"></div>
</ion-content>

<style>
  ::ng-deep .check-preview {
    --max-width: 440px;
    --height: 90%;
  }
</style>
