import { Injectable } from '@angular/core';
import {
  IPrintObject,
  PrinterService,
} from 'src/app/shared/printer/printer.service';
import { IReceipt } from './terminal.models';
import { ReceiptFormatterService } from './receipt-formatter.service';
import {
  ITerminalData,
  TerminalDataService,
} from './services/terminal-data.service';
import { OrderDTO } from './repositories/orders.repository';
import { CheckComponent } from 'src/app/shared/printer/components/check.component';
import { ITaxOrder } from 'src/app/common/tax/models/tax-order.model';
import { IPrinterItem } from './repositories/printers.repository';
import DateUtils from 'src/app/utility/date-utils';
import { ModalController } from '@ionic/angular';
import MoneyUtils from 'src/app/utility/money-utils';
import { APP_CONFIG, SettingsService } from 'src/app/services/settings.service';
import OrderUtils from 'src/app/utility/order-utils';
import { ShiftTotalsOrderType } from 'src/app/common/tax/models/tax.model';
import { CurrencyService } from 'src/app/services/currency.service';
import { WorkAreasRepository } from './repositories/workarea.repository';
import { PrintLine } from 'src/app/models/print-line.model';
import { OrderModel } from 'src/app/orders/order.model';

class PrintProduct {
  amount: number;
  code: number;
  letters: string;
  name: string;
  cost: number;
  quantity: number;
  price: number;
  uktZedCode: string;
  unitCode: string;
  unitName: string;
  exciseLabels?: string[];
}

class ZREP {
  ZREPHEAD: ZREPHEAD;
  ZREPREALIZ: ZREPREALIZ;
  ZREPRETURN: ZREPRETURN;
  ZREPBODY: ZREPBODY;
}

class ZREPHEAD {
  'UID': string;
  'TIN': number;
  IPN: number;
  'ORGNM': string;
  'POINTNM': string;
  'POINTADDR': string;
  'ORDERDATE': string;
  'ORDERTIME': string;
  'ORDERNUM': number;
  'CASHDESKNUM': number;
  'CASHREGISTERNUM': number;
  'CASHIER': string;
  'VER': number;
  'ORDERTAXNUM': string;
  TESTING: 'true';
}

class ZREPREALIZ {
  'SUM': number;
  'ORDERSCNT': number;
  'PAYFORMS': any;
  TAXES: any;
}
class ZREPRETURN {
  'SUM': number;
  'ORDERSCNT': number;
  'PAYFORMS': any;
  TAXES: any;
}

class ZREPBODY {
  SERVICEINPUT: number;
  SERVICEOUTPUT: number;
}

const SOFT_PROVIDER_NAME = 'TurboPOS';

@Injectable({ providedIn: 'root' })
export class ReceiptService {
  private printers: IPrinterItem[];

  private get terminalData(): ITerminalData {
    return this._terminalData.terminalSettings();
  }
  constructor(
    private printerService: PrinterService,
    private _terminalData: TerminalDataService,
    private modalController: ModalController,
    private settings: SettingsService,
    private currencyService: CurrencyService,
    private _workAreasRep: WorkAreasRepository
  ) {}

  async printTaxOrder(taxOrder: ITaxOrder): Promise<void> {
    const terminalSettings = this._terminalData.terminalSettings();
    const logo = terminalSettings.printer_settings.logo;
    const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
    const taxLink = OrderUtils.getTaxLink(
      taxCheckUrl,
      taxOrder.ORDERTAXNUM,
      taxOrder.cashRegisterNum,
      taxOrder.CHECKTOTAL,
      new Date().toISOString()
    );

    if (!this.printerService.isPrinterConfigured) {
      this.displayReceipt(
        taxOrder.toString(32, this.currencyService.name),
        taxLink
      );
    }

    if (this.printerService.hasPrinters) {
      await this._printTaxOrder(taxOrder, taxLink);
    }
  }

  printXmlCheck(xml: string) {
    const lines = this._getXmlCheckLines(xml);
    this.printerService.printLines(lines);
  }

  private _getXmlCheckLines(xml: string): PrintLine[] {
    const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
    const terminalSettings = this._terminalData.terminalSettings();
    const logo = terminalSettings.printer_settings.logo;
    const lines = OrderUtils.xmlDocToLines(xml, taxCheckUrl);
    if (logo) {
      lines.unshift({ type: 'empty' });
      lines.unshift({ type: 'logoImage', value: logo });
    }

    const wish = this._getNext();
    if (wish) {
      lines.push({ type: 'empty' });
      lines.push({ type: 'text', value: wish });
    }

    const ps = this.terminalData.printer_settings;
    if (ps?.receipt_footer) {
      lines.push({ type: 'ruler' });
      lines.push({
        type: 'text',
        value: ps.receipt_footer,
      });
    }

    return lines;
  }

  async printOrderModel(order: OrderModel) {
    const lines = OrderUtils.orderModelToPrintLines(order);
    if (!order.saleOrderId) {
      const terminalSettings = this._terminalData.terminalSettings();
      if (terminalSettings.printer_settings.logo) {
        lines.unshift({ type: 'empty' });
        lines.unshift({
          type: 'logoImage',
          value: terminalSettings.printer_settings.logo,
        });
      }

      const wish = this._getNext();
      if (wish) {
        lines.push({ type: 'empty' });
        lines.push({ type: 'text', value: wish });
      }

      const ps = this.terminalData.printer_settings;
      if (ps?.receipt_footer) {
        lines.push({ type: 'ruler' });
        lines.push({
          type: 'text',
          value: ps.receipt_footer,
        });
      }
    }

    return this.printerService.printLines(lines);
  }

  displayReceipt(receipt: string, qrCodeData: string, logo?: string) {
    this.modalController
      .create({
        component: CheckComponent,
        componentProps: {
          printText: receipt,
          qrCodeData: qrCodeData,
          logoDataUrl: logo,
        },
        canDismiss: true,
        backdropDismiss: true,
        cssClass: 'check-preview',
      })
      .then((modal) => modal.present());
  }

  opnenReceipt(
    receipt: IReceipt | OrderDTO,
    qrCodePayment?: { msgBefore: string; msgAfter: string; url: string }
  ) {
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
    }

    const checkXml = receipt.xmlCheck || receipt.rroXmlReceipt;
    let lines: PrintLine[];
    if (checkXml) {
      lines = this._getXmlCheckLines(checkXml);
    } else {
      const order = new OrderDTO(receipt);
      const terminalSettings = this._terminalData.terminalSettings();
      const showOrderNumber = terminalSettings.terminal.checkshowordernumber;
      lines = OrderUtils.orderToPrintLines(
        order,
        receipt_header,
        showOrderNumber
      );
    }

    if (qrCodePayment) {
      lines.push({ type: 'empty' });
      lines.push({ type: 'ruler' });
      lines.push({ type: 'text', value: qrCodePayment.msgBefore });
      lines.push({ type: 'qrcode', value: qrCodePayment.url });
      lines.push({ type: 'text', value: qrCodePayment.msgAfter });
    }

    if (receipt_footer) {
      lines.push({ type: 'ruler' });
      lines.push({
        type: 'text',
        value: receipt_footer,
      });
    }

    this.printerService.displayLines(lines, receipt.uuid);
  }

  printDelivery(order: IReceipt): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    order.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(printer);
    let printText: string;
    if (printer.type == 'web') {
      printText = this._getDeliveryHtml(order);
    } else {
      const lines = this._getDeliveryLines(order);
      printText = _receiptFormatterService.create(lines);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  printDelivery2(delivery: any): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    delivery.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(printer);
    let printText: string;
    if (printer.type == 'web') {
      // printText = this._getDeliveryHtml(order);
    } else {
      const lines = this._getDeliveryLines(delivery);
      printText = _receiptFormatterService.create(lines);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  async printWorkAreas(receipt: IReceipt): Promise<void> {
    const order = new OrderDTO(receipt);
    const workAreas = await this._workAreasRep.getWorkAreas();
    const workAreaLines = OrderUtils.workAreasToPrintLines(order, workAreas);

    this.printerService.printLinesMultiple(workAreaLines);
  }

  printPreOrder(
    receipt: IReceipt | OrderDTO,
    qrCodePayment: { msgBefore: string; msgAfter: string; url: string }
  ): void {
    const ps = this.terminalData.printer_settings;
    const order = new OrderDTO(receipt);

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
      order.pointAddress = address;
    }
    order.storeName = this.terminalData.store.name;
    const lines = OrderUtils.preOrderPrintLines(order);

    const terminalSettings = this._terminalData.terminalSettings();
    if (terminalSettings.printer_settings.logo) {
      lines.unshift({ type: 'empty' });
      lines.unshift({
        type: 'logoImage',
        value: terminalSettings.printer_settings.logo,
      });
    }

    if (qrCodePayment) {
      lines.push({ type: 'empty' });
      lines.push({ type: 'ruler' });
      lines.push({ type: 'text', value: qrCodePayment.msgBefore });
      lines.push({ type: 'qrcode', value: qrCodePayment.url });
      lines.push({ type: 'text', value: qrCodePayment.msgAfter });
    }

    this.printerService.printLines(lines);
  }

  printOrder(order: IReceipt | OrderDTO, isCopy = false): void {
    this._printOrder(order, isCopy);
  }

  async _printTaxOrder(taxOrder: ITaxOrder, taxLink: string): Promise<void> {
    this.printers = this.printerService.printers;
    const selectedPrinter = this.printers.find((p) => p.printCheck);
    if (!selectedPrinter) {
      return;
    }

    let text = '';
    if (selectedPrinter.type == 'web') {
      text = taxOrder.toHtml();
    } else {
      const checkWidth =
        this.printerService.getCharectersLength(selectedPrinter);

      text = taxOrder.toString(checkWidth, this.currencyService.name);
    }

    let printObject = this.printerService.getPrintObject(
      selectedPrinter,
      text,
      true
    );
    printObject.bottomQRCodeData = taxLink;
    if (taxLink) {
      printObject.bottomText = SOFT_PROVIDER_NAME;
    }

    const terminalSettings = this._terminalData.terminalSettings();
    if (selectedPrinter.printLogo) {
      printObject.imglogo = terminalSettings.printer_settings.logo;
    }

    return this.printerService.printScope([printObject]);
  }

  async printReceipt(
    receipt: IReceipt | OrderDTO,
    isCopy = false
  ): Promise<void> {
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
    }

    const wish = this._getNext();
    const order = new OrderDTO(receipt);

    const terminalSettings = this._terminalData.terminalSettings();
    const logo = terminalSettings.printer_settings.logo;

    if (order.xmlCheck) {
      this.printXmlCheck(order.xmlCheck);
    } else {
      const showOrderNumber = terminalSettings.terminal.checkshowordernumber;
      const lines = OrderUtils.orderToPrintLines(
        order,
        receipt_header,
        showOrderNumber
      );

      if (logo) {
        lines.unshift({ type: 'empty' });
        lines.unshift({ type: 'logoImage', value: logo });
      }

      if (receipt_footer) {
        lines.push({ type: 'ruler' });
        lines.push({
          type: 'text',
          value: receipt_footer,
        });
      }

      if (wish) {
        lines.push({ type: 'empty' });
        lines.push({
          type: 'text',
          value: wish,
          align: 'center',
        });
      }

      this.printerService.printLines(lines, null, order.uuid);
    }

    if (isCopy) {
      return;
    }

    const workAreas = await this._workAreasRep.getWorkAreas();
    const workAreaLines = OrderUtils.workAreasToPrintLines(order, workAreas);
    this.printerService.printLinesMultiple(workAreaLines, true);
  }

  async _printOrder(
    receipt: IReceipt | OrderDTO,
    isCopy = false
  ): Promise<void> {
    const ps = this.terminalData.printer_settings;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    if (ps && ps.print_address) {
      receipt.pointAddress = this.terminalData.store.address;
    }

    const wish = this._getNext();
    const order = new OrderDTO(receipt);

    const terminalSettings = this._terminalData.terminalSettings();
    const showOrderNumber = terminalSettings.terminal.checkshowordernumber;

    const lines = OrderUtils.orderToPrintLines(
      order,
      receipt_header,
      showOrderNumber
    );

    if (terminalSettings.printer_settings.logo) {
      lines.unshift({ type: 'empty' });
      lines.unshift({
        type: 'logoImage',
        value: terminalSettings.printer_settings.logo,
      });
    }

    if (receipt_footer) {
      lines.push({ type: 'ruler' });
      lines.push({
        type: 'text',
        value: receipt_footer,
      });
    }

    if (wish) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: wish,
        align: 'center',
      });
    }

    this.printerService.printLines(lines, null, order.uuid);

    if (isCopy) {
      return;
    }

    const workAreas = await this._workAreasRep.getWorkAreas();
    const workAreaLines = OrderUtils.workAreasToPrintLines(order, workAreas);
    this.printerService.printLinesMultiple(workAreaLines, true);
  }

  async printSalesReport(report: any): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    report.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(printer);

    let printText: string;
    if (printer.type == 'web') {
      printText = this._getSalesReportHtml(report);
    } else {
      printText = this._getSalesReportString(report, _receiptFormatterService);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  printReport(taxOrder: ITaxOrder): Promise<void> {
    if (!taxOrder) {
      alert('Немає даних для звіту');
      return;
    }

    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const _selectedPrinter = this.printerService.printers.find(
      (p) => p.printCheck
    );

    if (!_selectedPrinter) {
      return;
    }

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(_selectedPrinter);
    let _zReportText = '';
    if (_selectedPrinter.type == 'web') {
      _zReportText = this._reportOutputHtmlFromObject(taxOrder);
    } else {
      _zReportText = this._reportOutputText(_receiptFormatterService, taxOrder);
    }

    let printObject = this.printerService.getPrintObject(
      _selectedPrinter,
      _zReportText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  getTaxReportText(taxOrder: ITaxOrder, header = true): string {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(null);
    const _zReportText = this._reportOutputText(
      _receiptFormatterService,
      taxOrder,
      header
    );

    return _zReportText;
  }

  printReportDocument(xmlString: string): Promise<void> {
    if (!xmlString) {
      alert('Немає даних для звіту');
      return;
    }

    const xmlDocument = OrderUtils.parseXmlDoc(xmlString);
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const _selectedPrinter = this.printerService.printers.find(
      (p) => p.printCheck
    );

    if (!_selectedPrinter) {
      return;
    }

    _receiptFormatterService.config.width =
      this.printerService.getCharectersLength(_selectedPrinter);
    let _zReportText = '';
    if (_selectedPrinter.type == 'web') {
      _zReportText = this._reportOutputHtmlFromXml(xmlDocument.ZREP);
    } else {
      _zReportText = this._reportOutputTextFromXml(
        _receiptFormatterService,
        xmlDocument.ZREP
      );
    }

    let printObject = this.printerService.getPrintObject(
      _selectedPrinter,
      _zReportText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  private _getDeliveryLines(delivery: any) {
    const lines: any[] = [];

    lines.push({ type: 'empty' });

    if (delivery.store_name) {
      lines.push({
        type: 'text',
        value: [delivery.store_name],
        align: 'center',
      });
    }
    lines.push({
      type: 'text',
      value: ['Доставка'],
      align: 'center',
    });

    const _values: string[] = [];
    if (delivery.deliveryMethod) {
      _values.push(delivery.deliveryMethod.name);
    }

    let _address1 = '';

    if (delivery.street) {
      _address1 += `вул. ${delivery.street}`;
    }

    if (delivery.houseNumber) {
      _address1 += ` буд. ${delivery.houseNumber}`;
    }

    if (delivery.houseCode) {
      _address1 += ` корп. ${delivery.houseCode}`;
    }

    _values.push(_address1);

    let _address2 = '';
    if (delivery.flatNumber) {
      _address1 += `кв. ${delivery.flatNumber}`;
    }
    if (delivery.floor) {
      _address1 += ` поверх ${delivery.floor}`;
    }

    _values.push(_address2);

    lines.push({
      type: 'text',
      value: _values,
      align: 'left',
    });

    if (delivery.deliveryMethod.price) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Вартість',
            value: MoneyUtils.money(delivery.deliveryMethod.price),
          },
        ],
      });
    }

    if (delivery.note) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: 'Коментар:',
      });

      lines.push({
        type: 'text',
        value: delivery.note,
        align: 'center',
      });
    }
  }

  private _reportOutputTextFromXml(formatter: any, xmlObject: ZREP): string {
    let lines: any[] = [];

    const orgType = xmlObject.ZREPHEAD.TIN ? 'ФОП' : 'ТОВ';
    if (xmlObject.ZREPHEAD.ORGNM) {
      lines.push({
        type: 'text',
        value: `${orgType} ${xmlObject.ZREPHEAD.ORGNM}`,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.POINTNM) {
      lines.push({
        type: 'text',
        value: xmlObject.ZREPHEAD.POINTNM,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.POINTADDR) {
      lines.push({
        type: 'text',
        value: xmlObject.ZREPHEAD.POINTADDR,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.TIN) {
      lines.push({
        type: 'text',
        value: '      ІД ' + xmlObject.ZREPHEAD.TIN,
      });
    }

    if (xmlObject.ZREPHEAD.CASHREGISTERNUM) {
      lines.push({
        type: 'text',
        value:
          'ПРРО    ФН ' +
          xmlObject.ZREPHEAD.CASHREGISTERNUM +
          '    ВН ' +
          xmlObject.ZREPHEAD.CASHDESKNUM,
      });
    }

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      lines.push({
        type: 'text',
        value:
          'Z-ЗВІТ  ФН ' +
          xmlObject.ZREPHEAD.ORDERTAXNUM +
          '   ВН ' +
          xmlObject.ZREPHEAD.ORDERNUM,
      });
    } else {
      lines.push({
        type: 'text',
        value: 'X-ЗВІТ',
      });
    }

    if (xmlObject.ZREPHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.CASHIER) {
      lines.push({
        type: 'text',
        value: 'Касир ' + xmlObject.ZREPHEAD.CASHIER,
      });
    }

    lines.push({
      type: 'text',
      value: '-------------------------------',
    });

    if (xmlObject.ZREPBODY) {
      if (xmlObject.ZREPBODY.SERVICEINPUT || xmlObject.ZREPBODY.SERVICEOUTPUT) {
        if (xmlObject.ZREPBODY.SERVICEINPUT) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'СЛУЖБОВЕ ВНЕСЕННЯ',
                value: MoneyUtils.money(xmlObject.ZREPBODY.SERVICEINPUT),
                align: 'right',
              },
            ],
          });
        }

        if (xmlObject.ZREPBODY.SERVICEOUTPUT) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'СЛУЖБОВА ВИДАЧА',
                value: MoneyUtils.money(xmlObject.ZREPBODY.SERVICEOUTPUT),
                align: 'right',
              },
            ],
          });
        }

        lines.push({
          type: 'text',
          value: '-------------------------------',
        });
      }
    }

    const realizeLines = this._reportRegtailzBlock(
      xmlObject.ZREPREALIZ,
      '     ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );

    const returnLines = this._reportRegtailzBlock(
      xmlObject.ZREPRETURN,
      '     ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    lines = [...lines, ...realizeLines, ...returnLines];

    const dateTimeString = OrderUtils.getTaxDocumentDateTimeString(
      xmlObject.ZREPHEAD.ORDERDATE,
      xmlObject.ZREPHEAD.ORDERTIME
    );
    lines.push({
      type: 'text',
      value: dateTimeString,
    });

    if (xmlObject.ZREPHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    } else {
      lines.push({
        type: 'text',
        value: 'ФІСКАЛЬНИЙ Z-ЗВІТ',
        align: 'center',
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _reportRegtailzBlock(realizBlock: any, title: string): any[] {
    const lines: any[] = [];
    if (!realizBlock) {
      return lines;
    }

    lines.push({
      type: 'text',
      value: title,
    });

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Загальна сума',
          value: MoneyUtils.money(realizBlock.SUM),
          align: 'right',
        },
      ],
    });

    if (realizBlock.PAYFORMS && realizBlock.PAYFORMS.ROW) {
      if (realizBlock.PAYFORMS.ROW.length) {
        for (let pf of realizBlock.PAYFORMS.ROW) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: '- ' + pf.PAYFORMNM,
                value: MoneyUtils.money(pf.SUM),
                align: 'right',
              },
            ],
          });
        }
      } else {
        const payorm = realizBlock.PAYFORMS.ROW;
        lines.push({
          type: 'properties',
          lines: [
            {
              name: '- ' + payorm.PAYFORMNM,
              value: MoneyUtils.money(payorm.SUM),
              align: 'right',
            },
          ],
        });
      }
    }

    if (realizBlock.TAXES && realizBlock.TAXES.ROW) {
      lines.push({
        type: 'text',
        value: '     ПОДАТКИ',
      });
      if (realizBlock.TAXES.ROW.length) {
        for (let tax of realizBlock.TAXES.ROW) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: `${tax.NAME} ${tax.LETTER}   ${
                  tax.PRC
                }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
                value: MoneyUtils.money(tax.SUM),
                align: 'right',
              },
            ],
          });
        }
      } else {
        const tax = realizBlock.TAXES.ROW;
        lines.push({
          type: 'properties',
          lines: [
            {
              name: `${tax.NAME} ${tax.LETTER}   ${
                tax.PRC
              }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
              value: MoneyUtils.money(tax.SUM),
              align: 'right',
            },
          ],
        });
      }
    }

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Чеків',
          value: realizBlock.ORDERSCNT,
          align: 'right',
        },
      ],
    });

    lines.push({
      type: 'text',
      value: '-------------------------------',
      align: 'center',
    });

    return lines;
  }

  private _reportOutputHtmlFromXml(xmlObject: ZREP): string {
    let reportHtml = `<div style="width: 100%;font-size: 14px;">`;

    const orgType = xmlObject.ZREPHEAD.TIN ? 'ФОП' : 'ТОВ';
    if (xmlObject.ZREPHEAD.ORGNM) {
      reportHtml += `<div style="text-align: center;">${orgType} ${xmlObject.ZREPHEAD.ORGNM}</div>`;
    }

    if (xmlObject.ZREPHEAD.POINTNM) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.POINTNM}</div>`;
    }

    if (xmlObject.ZREPHEAD.POINTADDR) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.POINTADDR}</div>`;
    }

    if (xmlObject.ZREPHEAD.TIN) {
      reportHtml += `<div style="text-align: center;">ІД ${xmlObject.ZREPHEAD.TIN}</div>`;
    }

    if (xmlObject.ZREPHEAD.IPN) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.IPN}</div>`;
    }

    if (xmlObject.ZREPHEAD.CASHREGISTERNUM) {
      reportHtml += `<div>ПРРО    ФН ${xmlObject.ZREPHEAD.CASHREGISTERNUM}    ВН ${xmlObject.ZREPHEAD.CASHDESKNUM}</div>`;
    }

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      reportHtml += `<div>Z-ЗВІТ  ФН ${xmlObject.ZREPHEAD.ORDERTAXNUM}   ВН ${xmlObject.ZREPHEAD.ORDERNUM}</div>`;
    } else {
      reportHtml += `<div>X-ЗВІТ</div>`;
    }

    if (xmlObject.ZREPHEAD.TESTING) {
      reportHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (xmlObject.ZREPHEAD.CASHIER) {
      reportHtml += `<div>Касир ${xmlObject.ZREPHEAD.CASHIER}</div>`;
    }
    reportHtml += `<div style="text-align: center;">-------------------------------</div>`;

    if (xmlObject.ZREPBODY) {
      if (xmlObject.ZREPBODY.SERVICEINPUT || xmlObject.ZREPBODY.SERVICEOUTPUT) {
        if (xmlObject.ZREPBODY.SERVICEINPUT) {
          reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВЕ ВНЕСЕННЯ</td><td>${MoneyUtils.money(
            xmlObject.ZREPBODY.SERVICEINPUT
          )}</td></tr></table>`;
        }

        if (xmlObject.ZREPBODY.SERVICEOUTPUT) {
          reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВА ВИДАЧА</td><td>${MoneyUtils.money(
            xmlObject.ZREPBODY.SERVICEOUTPUT
          )}</td></tr></table>`;
        }

        reportHtml += `<div style="text-align: center;">-------------------------------</div>`;
      }
    }

    reportHtml += this._reportRegtailzBlockHtml(
      xmlObject.ZREPREALIZ,
      'ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    reportHtml += this._reportRegtailzBlockHtml(
      xmlObject.ZREPRETURN,
      'ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    const dateTimeString = OrderUtils.getTaxDocumentDateTimeString(
      xmlObject.ZREPHEAD.ORDERDATE,
      xmlObject.ZREPHEAD.ORDERTIME
    );
    reportHtml += `<div style="text-align: center;">${dateTimeString}</div>`;

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      if (xmlObject.ZREPHEAD.TESTING) {
        reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
      } else {
        reportHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ Z-ЗВІТ</div>`;
      }
    } else {
      reportHtml += `<div style="text-align: center;">X-ЗВІТ</div>`;
    }

    reportHtml += `</div>`;
    return reportHtml;
  }

  private _reportRegtailzBlockHtml(realizBlock: any, title: string): string {
    let block = '';
    if (!realizBlock) {
      return block;
    }

    block += `<div style="padding-left: 30px">${title}</div>`;
    block += `<table><tr><td style="width:100%">Загальна сума</td><td>${MoneyUtils.money(
      realizBlock.SUM
    )}</td></tr></table>`;

    if (realizBlock.PAYFORMS && realizBlock.PAYFORMS.ROW) {
      block += '<table>';

      if (realizBlock.PAYFORMS.ROW.length) {
        for (let pf of realizBlock.PAYFORMS.ROW) {
          block += `<tr><td style="width:100%"> - ${
            pf.PAYFORMNM
          }</td><td>${MoneyUtils.money(pf.SUM)}</td></tr>`;
        }
      } else {
        const payorm = realizBlock.PAYFORMS.ROW;
        block += `<tr><td style="width:100%"> - ${
          payorm.PAYFORMNM
        }</td><td>${MoneyUtils.money(payorm.SUM)}</td></tr>`;
      }

      block += '</table>';
    }

    block += `<table><tr><td style="width:100%">Чеків</td><td>${realizBlock.ORDERSCNT}</td></tr></table>`;
    block += `<div style="text-align: center;">-------------------------------</div>`;

    if (realizBlock.TAXES && realizBlock.TAXES.ROW) {
      block += `<div style="padding-left: 30px">ПОДАТКИ</div>`;

      block += '<table>';

      if (realizBlock.TAXES.ROW.length) {
        for (let tax of realizBlock.TAXES.ROW) {
          block += `<tr><td style="width:100%"> - ${tax.NAME} ${tax.LETTER}   ${
            tax.PRC
          }% від ${MoneyUtils.money(tax.SOURCESUM)}:</td><td>${MoneyUtils.money(
            tax.SUM
          )}</td></tr>`;
        }
      } else {
        const tax = realizBlock.TAXES.ROW;
        block += `<tr><td style="width:100%"> - ${tax.NAME} ${tax.LETTER}   ${
          tax.PRC
        }% від ${MoneyUtils.money(tax.SOURCESUM)}</td><td>${MoneyUtils.money(
          tax.SUM
        )}</td></tr>`;
      }

      block += '</table>';
      block += `<div style="text-align: center;">-------------------------------</div>`;
    }

    return block;
  }

  private _reportOutputHtmlFromObject(taxOrder: ITaxOrder): string {
    let reportHtml = `<div style="width: 100%;font-size: 12px;">`;

    const orgType = taxOrder.tin ? 'ФОП' : 'ТОВ';
    if (taxOrder.organizationName) {
      reportHtml += `<div style="text-align: center;">${orgType} ${taxOrder.organizationName}</div>`;
    }

    if (taxOrder.pointName) {
      reportHtml += `<div style="text-align: center;">${taxOrder.pointName}</div>`;
    }

    if (taxOrder.pointAddress) {
      reportHtml += `<div style="text-align: center;">${taxOrder.pointAddress}</div>`;
    }

    if (taxOrder.tin) {
      reportHtml += `<div style="text-align: center;">${taxOrder.tin}</div>`;
    }

    if (taxOrder.cashRegisterNum) {
      reportHtml += `<div>ПРРО    ФН ${taxOrder.cashRegisterNum}    ВН ${taxOrder.cashDeskNum}</div>`;
    }

    if (taxOrder.ORDERTAXNUM) {
      reportHtml += `<div>Z-ЗВІТ  ФН ${taxOrder.ORDERTAXNUM}   ВН ${taxOrder.orderNumber}</div>`;
    } else {
      reportHtml += `<div>X-ЗВІТ</div>`;
    }

    if (taxOrder.testing) {
      reportHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (taxOrder.cashier) {
      reportHtml += `<div>Касир ${taxOrder.cashier}</div>`;
    }
    reportHtml += `<div style="text-align: center;">-------------------------------</div>`;

    if (
      taxOrder.shiftTotals?.ServiceInput ||
      taxOrder.shiftTotals?.ServiceOutput
    ) {
      if (taxOrder.shiftTotals.ServiceInput) {
        reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВЕ ВНЕСЕННЯ</td><td>${MoneyUtils.money(
          taxOrder.shiftTotals.ServiceInput
        )}</td></tr></table>`;
      }

      if (taxOrder.shiftTotals.ServiceOutput) {
        reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВА ВИДАЧА</td><td>${MoneyUtils.money(
          taxOrder.shiftTotals.ServiceOutput
        )}</td></tr></table>`;
      }

      reportHtml += `<div style="text-align: center;">-------------------------------</div>`;
    }

    reportHtml += this._shiftTotalsOrderTypeHtml(
      taxOrder.shiftTotals?.Real,
      'ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    reportHtml += this._shiftTotalsOrderTypeHtml(
      taxOrder.shiftTotals?.Ret,
      'ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    reportHtml += `<div style="text-align: center;">${DateUtils.display(
      taxOrder.documentDate
    )}</div>`;

    if (taxOrder.testing) {
      reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (taxOrder.ORDERTAXNUM) {
      if (taxOrder.testing) {
        reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
      } else {
        reportHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ Z-ЗВІТ</div>`;
      }
    } else {
      reportHtml += `<div style="text-align: center;">X-ЗВІТ</div>`;
    }

    reportHtml += `</div>`;
    return reportHtml;
  }

  private _shiftTotalsOrderTypeHtml(
    orderType: ShiftTotalsOrderType,
    title: string
  ): string {
    let orderTypeHtml = '';
    if (!orderType) {
      return orderTypeHtml;
    }

    orderTypeHtml += `<div style="padding-left: 30px">     ${title}</div>`;
    orderTypeHtml += `<table><tr><td style="width:100%">Загальна сума</td><td>${MoneyUtils.money(
      orderType?.Sum
    )}</td></tr></table>`;

    if (orderType?.PayForm) {
      orderTypeHtml += '<table>';

      for (let pf of orderType.PayForm) {
        orderTypeHtml += `<tr><td style="width:100%"> - ${
          pf.PayFormName
        }</td><td>${MoneyUtils.money(pf.Sum)}</td></tr>`;
      }

      orderTypeHtml += '</table>';
    }

    if (orderType?.Tax) {
      orderTypeHtml += `<div style="padding-left: 30px">ПОДАТКИ</div>`;

      orderTypeHtml += '<table>';
      for (let tax of orderType.Tax) {
        orderTypeHtml += `<tr><td style="width:100%"> - ${tax.Name} ${
          tax.Letter
        }   ${tax.Prc}% від ${MoneyUtils.money(
          tax.SourceSum
        )}:</td><td>${MoneyUtils.money(tax.Sum)}</td></tr>`;
      }

      orderTypeHtml += '</table>';
      orderTypeHtml += `<div style="text-align: center;">-------------------------------</div>`;
    }

    orderTypeHtml += `<table><tr><td style="width:100%">Чеків</td><td>${
      orderType?.OrdersCount || 0
    }</td></tr></table>`;
    orderTypeHtml += `<div style="text-align: center;">-------------------------------</div>`;

    return orderTypeHtml;
  }

  private _getSalesReportHtml(report: any): string {
    let reportHtml = `<div style="text-align: center;">${report.store_name}</div>`;

    reportHtml += `<table style="width:100%">`;
    reportHtml += `<tr><td>Дата з</td><td style="text-align:right;">${DateUtils.formatCustom(
      report.date_form,
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `<tr><td>Дата до</td><td style="text-align:right;">${DateUtils.formatCustom(
      report.date_to,
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `<tr><td>Надруковано</td><td style="text-align:right;">${DateUtils.formatCustom(
      new Date(),
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `</table>`;

    reportHtml += `<table style="width:100%">`;
    report.products.forEach((p, i) => {
      reportHtml += `<tr><td>${i + 1}</td><td>${
        p.name
      }</td><td  style="text-align:right;">${
        p.quantity
      }</td><td  style="text-align:right;">${
        p.price
      }</td><td  style="text-align:right;">${p.amount}</td></tr>`;
    });
    reportHtml += `</table>`;
    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    if (report.groups && report.groups.length) {
      for (let g of report.groups) {
        reportHtml += `<div style="font-size: 16px; font-weight: bold;"">${g.name}</div>`;

        reportHtml += `<table style="width:100%">`;
        g.products.forEach((gp) => {
          reportHtml += `<tr><td>${gp.name}</td><td>${gp.quantity}</td><td>${gp.price}</td><td>${gp.amount}</td></tr>`;
        });
        reportHtml += `</table>`;

        reportHtml += `<table style="width:100%; font-size: 16px; font-weight: bold;">`;

        if (g.totaldiscount > 0) {
          reportHtml += `<tr><td colspan="4">Разом</td><td style="text-align: right">${g.amount}</td></tr>`;
          reportHtml += `<tr><td colspan="4">Знижка</td><td style="text-align: right">${g.totaldiscount}</td></tr>`;
        }

        reportHtml += `<tr><td colspan="4">Сума</td><td style="text-align: right">${g.income}</td></tr>`;
        reportHtml += `</table>`;
      }
    }

    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    if (report.ingredients) {
      reportHtml += `<table style="width:100%">`;
      report.ingredients.forEach((p) => {
        reportHtml += `<tr><td>${p.name}</td><td>${p.quantity}</td><td>${p.unit}</td></tr>`;
      });
      reportHtml += `</table>`;
    }

    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    reportHtml += `<table style="width:100%">`;
    reportHtml += `<tr><td>Товарів в чеку</td><td style="text-align: right">${report.avarageproductsperreceipt}</td></tr>`;
    reportHtml += `<tr><td>Середній чек</td><td style="text-align: right">${report.averagereceipttotal}</td></tr>`;
    reportHtml += `<tr><td>Всього</td><td style="text-align: right">${report.amount}</td></tr>`;
    reportHtml += `<tr><td>     Готівка</td><td style="text-align: right">${report.totalcash}</td></tr>`;
    reportHtml += `<tr><td>     Карта</td><td style="text-align: right">${report.totalcashless}</td></tr>`;
    reportHtml += `<tr><td>  Знижки</td><td style="text-align: right">- ${report.totaldiscount}</td></tr>`;
    reportHtml += `<tr><td>Виручка</td><td style="text-align: right">${report.income}</td></tr>`;
    reportHtml += `<tr><td>     Готівка</td><td style="text-align: right">${report.incomecash}</td></tr>`;
    reportHtml += `<tr><td>     Карта</td><td style="text-align: right">${report.incomecashless}</td></tr>`;
    reportHtml += `<tr><td>  Витрати</td><td style="text-align: right">- ${report.expenses}</td></tr>`;
    reportHtml += `<tr style="font-size: 16px;font-weight: bold;"><td>Готівка</td><td style="text-align: right">${report.totalcashbox}</td></tr>`;

    reportHtml += `</table>`;
    return reportHtml;
  }

  private _reportOutputText(
    formatter: any,
    taxOrder: ITaxOrder,
    header = true
  ): string {
    let lines: any[] = [];

    const orgType = taxOrder.tin ? 'ФОП' : 'ТОВ';

    if (header) {
      if (taxOrder.organizationName) {
        lines.push({
          type: 'text',
          value: [`${orgType} ${taxOrder.organizationName}`],
        });
      }

      if (taxOrder.pointName) {
        lines.push({
          type: 'text',
          value: [taxOrder.pointName],
        });
      }

      if (taxOrder.pointAddress) {
        lines.push({
          type: 'text',
          value: [taxOrder.pointAddress],
        });
      }

      if (taxOrder.tin) {
        lines.push({
          type: 'text',
          value: ['      ІД ' + taxOrder.tin],
        });
      }

      if (taxOrder.cashRegisterNum) {
        lines.push({
          type: 'text',
          value: [
            'ПРРО    ФН ' +
              taxOrder.cashRegisterNum +
              '    ВН ' +
              taxOrder.cashDeskNum,
          ],
        });
      }

      if (taxOrder.ORDERTAXNUM) {
        lines.push({
          type: 'text',
          value: [
            'Z-ЗВІТ  ФН ' +
              taxOrder.ORDERTAXNUM +
              '   ВН ' +
              taxOrder.orderNumber,
          ],
        });
      } else {
        lines.push({
          type: 'text',
          value: ['X-ЗВІТ'],
        });
      }

      if (taxOrder.testing) {
        lines.push({
          type: 'text',
          value: ['ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ'],
        });
      }
    }

    if (taxOrder.cashier) {
      lines.push({
        type: 'text',
        value: ['Касир ' + taxOrder.cashier],
      });
    }

    lines.push({
      type: 'text',
      value: '-------------------------------',
    });

    if (
      taxOrder.shiftTotals?.ServiceInput ||
      taxOrder.shiftTotals?.ServiceOutput
    ) {
      if (taxOrder.shiftTotals.ServiceInput) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'СЛУЖБОВЕ ВНЕСЕННЯ',
              value: MoneyUtils.money(taxOrder.shiftTotals.ServiceInput),
              align: 'right',
            },
          ],
        });
      }

      if (taxOrder.shiftTotals.ServiceOutput) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'СЛУЖБОВА ВИДАЧА',
              value: MoneyUtils.money(taxOrder.shiftTotals.ServiceOutput),
              align: 'right',
            },
          ],
        });
      }

      lines.push({
        type: 'text',
        value: '-------------------------------',
      });
    }

    const real = this._shiftTotalsOrderType(
      taxOrder.shiftTotals?.Real,
      '     ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    const ret = this._shiftTotalsOrderType(
      taxOrder.shiftTotals?.Ret,
      '     ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    lines = [...lines, ...real, ...ret];
    const _now = new Date();
    lines.push({
      type: 'text',
      value: [DateUtils.display(_now)],
      align: 'center',
    });

    if (taxOrder.ORDERTAXNUM) {
      if (taxOrder.testing) {
        lines.push({
          type: 'text',
          value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
          align: 'center',
        });
      } else {
        lines.push({
          type: 'text',
          value: 'ФІСКАЛЬНИЙ Z-ЗВІТ',
          align: 'center',
        });
      }
    } else {
      lines.push({
        type: 'text',
        value: ['X-ЗВІТ'],
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _shiftTotalsOrderType(
    orderType: ShiftTotalsOrderType,
    title: string
  ): any[] {
    const lines: any[] = [];
    lines.push({
      type: 'text',
      value: title,
      align: 'center',
    });

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Загальна сума',
          value: MoneyUtils.money(orderType?.Sum),
          align: 'right',
        },
      ],
    });

    if (!orderType) {
      return lines;
    }

    for (let pf of orderType.PayForm) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: '- ' + pf.PayFormName,
            value: MoneyUtils.money(pf.Sum),
            align: 'right',
          },
        ],
      });
    }

    if (orderType.Tax && orderType.Tax.length) {
      lines.push({
        type: 'text',
        value: 'ПОДАТКИ',
      });
      for (let tax of orderType.Tax) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: ` - ${tax.Name} ${tax.Letter}   ${
                tax.Prc
              }% від ${MoneyUtils.money(tax.SourceSum)}`,
              value: MoneyUtils.money(tax.Sum),
              align: 'right',
            },
          ],
        });
      }
    }

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Чеків',
          value: orderType.OrdersCount?.toString(),
          align: 'right',
        },
      ],
    });

    lines.push({
      type: 'text',
      value: '-------------------------------',
      align: 'center',
    });

    return lines;
  }

  private _getProductsPrintText(
    receipt: OrderDTO,
    area_id: number = null
  ): any {
    if (!receipt.orderItems) {
      return null;
    }

    if (!area_id) {
      return {
        type: 'table',
        lines: receipt.orderItems.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
            code: p.uktZedCode,
            labels: p.exciseLabels,
          };
        }),
      };
    }

    const products = receipt.orderItems.filter((p) => p.workAreaId == area_id);
    if (products.length == 0) {
      return null;
    }

    return {
      type: 'table2',
      lines: products.map((p) => {
        return {
          item: p.name,
          qty: p.quantity,
          cost: p.price,
          total: p.amount,
        };
      }),
    };
  }

  private _getNext(): string {
    if (!this.terminalData.receipt_wishes) {
      return null;
    }

    const maxIndex = this.terminalData.receipt_wishes.length;
    if (maxIndex == 0) {
      return null;
    }

    if (this._lastWishIndex == 0 || this._lastWishIndex > 0) {
      this._lastWishIndex++;
    } else {
      this._lastWishIndex = 0;
    }

    if (this._lastWishIndex == maxIndex || this._lastWishIndex > maxIndex) {
      this._lastWishIndex = 0;
    }

    return this.terminalData.receipt_wishes[this._lastWishIndex];
  }

  private get _lastWishIndex(): number {
    const lastWishIndex = localStorage.getItem('LAST_WISH_INDEX');
    if (lastWishIndex) {
      return +lastWishIndex;
    } else {
      return 0;
    }
  }
  private set _lastWishIndex(lastWishIndex: number) {
    if (lastWishIndex == null || lastWishIndex == undefined) {
      return;
    }

    localStorage.setItem('LAST_WISH_INDEX', lastWishIndex.toString());
  }

  private _getRndWish(): string {
    if (!this.terminalData.receipt_wishes) {
      return null;
    }

    const maxIndex = this.terminalData.receipt_wishes.length;
    if (maxIndex == 0) {
      return null;
    }

    const rndWishIndex = this._getRndInteger(0, maxIndex);
    return this.terminalData.receipt_wishes[rndWishIndex];
  }

  private _getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  _getSalesReportString(
    report: any,
    formatter: ReceiptFormatterService
  ): string {
    report.store_name = this.terminalData.store.name;
    let lines: any[] = [
      {
        type: 'text',
        value: [report.store_name || ''],
        align: 'center',
      },
      { type: 'empty' },
      {
        type: 'properties',
        lines: [
          {
            name: 'Дата з',
            value: DateUtils.formatCustom(
              report.date_form,
              'dd MMM yyyy HH:mm'
            ),
          },
          {
            name: 'Дата до',
            value: DateUtils.formatCustom(report.date_to, 'dd MMM yyyy HH:mm'),
          },
          {
            name: 'Надруковано',
            value: DateUtils.formatCustom(new Date(), 'dd MMM yyyy HH:mm'),
          },
        ],
      },
      { type: 'empty' },
      {
        type: 'table',
        lines: report.products.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
          };
        }),
      },
    ];

    if (report.groups && report.groups.length) {
      for (let g of report.groups) {
        lines.push({ type: 'empty' });
        lines.push({
          type: 'text',
          value: [g.name || ''],
        });
        lines.push({
          type: 'table',
          lines: g.products.map((p) => {
            return {
              item: p.name,
              qty: p.quantity,
              cost: p.price,
              total: p.amount,
            };
          }),
        });

        if (g.totaldiscount > 0) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'Разом',
                value: g.amount,
              },
            ],
          });

          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'Знижка',
                value: g.totaldiscount,
              },
            ],
          });
        }

        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'Сума',
              value: g.income,
            },
          ],
        });
      }
    }

    lines = [
      ...lines,
      ...[
        { type: 'empty' },
        {
          type: 'table',
          lines: !report.ingredients
            ? []
            : report.ingredients.map((p) => {
                return {
                  item: p.name,
                  qty: p.quantity,
                  unit: p.unit,
                };
              }),
        },
        {
          type: 'properties',
          lines: [
            {
              name: 'Товарів в чеку',
              value: report.avarageproductsperreceipt,
            },
            {
              name: 'Середній чек',
              value: report.averagereceipttotal,
            },
          ],
        },
        { type: 'empty' },
        {
          type: 'properties',
          lines: [
            { name: 'Всього', value: report.amount },
            {
              name: '     Готівка',
              value: report.totalcash,
            },
            {
              name: '     Карта',
              value: report.totalcashless,
            },
            {
              name: '  Знижки',
              value: '-' + report.totaldiscount,
            },

            {
              name: 'Виручка',
              value: report.income,
            },
            {
              name: '     Готівка',
              value: report.incomecash,
            },
            {
              name: '     Карта',
              value: report.incomecashless,
            },
            {
              name: '  Витрати',
              value: '-' + report.expenses,
            },
            {
              name: 'Готівка',
              value: report.totalcashbox,
            },
          ],
        },
      ],
    ];

    return formatter.create(lines);
  }

  private _getDeliveryHtml(order: IReceipt) {
    let deliveryPrint = `<div style="text-align: center;font-size:18px">${order.store_name}</div>`;
    deliveryPrint += `<div style="text-align: center;font-size:18px">ДОСТАВКА</div>`;

    const delivery = order.delivery;
    deliveryPrint += `<div>Адреса доставки</div>`;
    deliveryPrint += `<table style="width:100%">`;
    deliveryPrint += `<tr><td>Місто</td><td style="text-align: right">${delivery.deliveryMethod.name}</td></tr>`;
    deliveryPrint += `<tr><td>Вулиця</td><td style="text-align: right">${delivery.street}</td></tr>`;
    deliveryPrint += `<tr><td>Будинок</td><td style="text-align: right">${delivery.houseNumber}</td></tr>`;
    deliveryPrint += `<tr><td>Корпус</td><td style="text-align: right">${delivery.houseCode}</td></tr>`;
    deliveryPrint += `<tr><td>Квартира</td><td style="text-align: right">${delivery.flatNumber}</td></tr>`;
    deliveryPrint += `<tr><td>Поверх</td><td style="text-align: right">${delivery.floor}</td></tr>`;
    deliveryPrint += `</table>`;
    deliveryPrint += `<div style="text-align: center">--------------------------------</div>`;

    if (delivery.note) {
      deliveryPrint += `<div>Коментар</div>`;
      deliveryPrint += `<div>${delivery.note}</div>`;
      deliveryPrint += `<div style="text-align: center">--------------------------------</div>`;
    }

    deliveryPrint += `<table style="width:100%">`;
    deliveryPrint += `<tr><td>ВАРТІСТЬ</td><td style="text-align: right">${MoneyUtils.money(
      delivery.price
    )}</td></tr>`;
    deliveryPrint += `</table>`;

    return deliveryPrint;
  }
}
