import { parseISO } from 'date-fns';
import { OrderModel } from '../orders/order.model';
import { OrderDTO } from '../private/terminal/repositories/orders.repository';
import DateUtils from './date-utils';
import MoneyUtils from './money-utils';
import * as QRCode from 'qrcode';
import { xml2json } from './xml-utils';
import { PrintLine } from '../models/print-line.model';
import { ITaxOrder } from '../common/tax/models/tax-order.model';

export default class OrderUtils {
  static getTaxLink(
    taxCheckUrl: string,
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    sm: number,
    dateStr: string
  ) {
    if (!fiscalNumber || !taxRegisterFiscalNumber) {
      return null;
    }

    let date: Date;
    if (!dateStr) {
      date = new Date();
    } else {
      date = parseISO(dateStr);
    }

    const _date = this.formatOrderDate(date);
    const _time = this.formatOrderTime(date);
    const _sm = sm.toFixed(2).replace(',', '.');
    return `${taxCheckUrl}?fn=${taxRegisterFiscalNumber}&id=${fiscalNumber}&date=${_date}&time=${_time}&sm=${_sm}`;
  }

  static getTaxCabinetLinkFromXml(xml: string, taxCheckUrl: string) {
    const doc = OrderUtils.parseXmlDoc(xml);
    if (!doc) {
      return null;
    }

    if (doc.CHECK) {
      const check = doc.CHECK;
      const taxCabinetLink = OrderUtils.getTaxCabinetLink(
        taxCheckUrl,
        check.CHECKHEAD.ORDERTAXNUM,
        check.CHECKHEAD.CASHREGISTERNUM,
        check.CHECKTOTAL.SUM,
        check.CHECKHEAD.ORDERDATE,
        check.CHECKHEAD.ORDERTIME
      );

      return taxCabinetLink;
    }

    if (doc.ZREP) {
      const zrep = doc.ZREP;
      const taxCabinetLink = OrderUtils.getTaxCabinetLink(
        taxCheckUrl,
        zrep.ZREPHEAD.ORDERTAXNUM,
        zrep.ZREPHEAD.CASHREGISTERNUM,
        zrep.ZREPHEAD.SUM,
        zrep.ZREPHEAD.ORDERDATE,
        zrep.ZREPHEAD.ORDERTIME
      );

      return taxCabinetLink;
    }
  }

  static getTaxCabinetLink(
    taxCheckUrl: string,
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    sm: string,
    date: string,
    time: string
  ) {
    const linkDate = this._getLinkDate(date);
    const linkTime = this._getLinkTime(time);

    return `${taxCheckUrl}?fn=${taxRegisterFiscalNumber}&id=${fiscalNumber}&date=${linkDate}&time=${linkTime}&sm=${sm}`;
  }

  private static _getLinkDate(orderDate: string) {
    const year = orderDate.substring(orderDate.length - 4, orderDate.length);
    const month = orderDate.substring(
      orderDate.length - 6,
      orderDate.length - 4
    );
    const day = orderDate.substring(0, orderDate.length - 6);

    return `${year}${month}${day}`;
  }

  private static _getLinkTime(orderTime: string) {
    const sec = orderTime.substring(orderTime.length - 2, orderTime.length);
    const mins = orderTime.substring(
      orderTime.length - 4,
      orderTime.length - 2
    );

    const hours = orderTime.substring(0, orderTime.length - 4);
    return `${hours}${mins}${sec}`;
  }

  static getTaxDocumentDateTimeString(
    orderDate: string,
    orderTime: string
  ): any {
    const dateTime = this.getTaxDocumentDateTime(orderDate, orderTime);

    return `${dateTime.day}-${dateTime.month}-${dateTime.year} ${dateTime.hours}:${dateTime.mins}:${dateTime.sec}`;
  }

  static getTaxDocumentDateTime(
    orderDate: string,
    orderTime: string
  ): {
    year: string;
    month: string;
    day: string;
    sec: string;
    mins: string;
    hours: string;
  } {
    const dateStr = orderDate; // 19112023 19-11-2023
    const timeStr = orderTime; // 13303 1:33:03

    const year = dateStr.substring(dateStr.length - 4, dateStr.length);
    const month = dateStr.substring(dateStr.length - 6, dateStr.length - 4);
    const day = dateStr.substring(0, dateStr.length - 6);
    const sec = timeStr.substring(timeStr.length - 2, timeStr.length);
    const mins = timeStr.substring(timeStr.length - 4, timeStr.length - 2);
    const hours = timeStr.substring(0, timeStr.length - 4);

    return {
      year,
      month,
      day,
      sec,
      mins,
      hours,
    };
  }

  static parseXmlDoc(xmlString: string): {
    CHECK?: {
      CHECKBODY: any;
      CHECKHEAD: any;
      CHECKTOTAL: any;
      CHECKPAY: any;
      CHECKTAX: any;
    };
    ZREP?: {
      ZREPBODY: any;
      ZREPHEAD: any;
      ZREPTOTAL: number;
      ZREPPAY: any;
      ZREPTAX: any;
      ZREPREALIZ: any;
      ZREPRETURN: any;
    };
  } {
    const doc = xml2json(
      xmlString,
      new Set([
        'ORDERTAXNUM',
        'CASHREGISTERNUM',
        'SUM',
        'ORDERDATE',
        'ORDERTIME',
        'TIN',
        'UID',
        'POSTRANSDATE',
      ])
    );

    return doc;
  }

  static async getTaxLinkQrCode(
    taxCheckUrl: string,
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    sm: number,
    date: string,
    width = '120'
  ) {
    if (!fiscalNumber) {
      return '';
    }

    const _taxLink = this.getTaxLink(
      taxCheckUrl,
      fiscalNumber,
      taxRegisterFiscalNumber,
      sm,
      date
    );
    return QRCode.toDataURL(_taxLink, {
      type: 'terminal',
      width: width,
    });
  }

  static formatOrderDate(date: Date) {
    let _date = `0${date.getDate()}`;
    _date = _date.substring(_date.length - 2);

    let _month = `0${date.getMonth() + 1}`;
    _month = _month.substring(_month.length - 2);

    const _year = date.getFullYear();
    return `${_year}${_month}${_date}`;
  }

  static formatOrderTime(date: Date) {
    let _hours = `0${date.getHours()}`;
    _hours = _hours.substring(_hours.length - 2);

    let _minutes = `0${date.getMinutes()}`;
    _minutes = _minutes.substring(_minutes.length - 2);

    let _seconds = `0${date.getSeconds()}`;
    _seconds = _seconds.substring(_seconds.length - 2);

    return `${_hours}${_minutes}${_seconds}`;
  }

  static orderModelToPrintLines(order: OrderModel): PrintLine[] {
    const lines: PrintLine[] = [];
    if (order.storeName) {
      lines.push({
        type: 'text',
        value: order.storeName,
        align: 'center',
      });
    }
    lines.push({ type: 'empty' });

    if (order.storeAddress) {
      lines.push({
        type: 'text',
        value: order.storeAddress,
        align: 'center',
      });
    }

    if (order.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: order.deadline
              ? DateUtils.formatCustom(order.deadline, 'dd MMM yyyy HH:mm')
              : '',
          },
        ],
      });
    }

    if (order.customer) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Клиент',
            value: order.customer,
          },
        ],
      });
    }

    if (order.saleOrderId) {
      lines.push({ type: 'empty' });
      lines.push({ type: 'text', value: 'ПОВЕРНЕННЯ', align: 'center' });
      lines.push({ type: 'empty' });
    }

    lines.push({ type: 'ruler' });
    for (let p of order.products) {
      const productLines: any[] = [];

      if (p.quantity == 1) {
        productLines.push({
          name: p.name,
          value: MoneyUtils.money(p.amount),
        });
      } else {
        productLines.push({
          name: `${p.quantity}  x  ${MoneyUtils.money(p.price)}`,
          value: '',
        });
        productLines.push({
          name: p.name,
          value: MoneyUtils.money(p.amount),
        });
      }

      lines.push({
        type: 'properties',
        lines: productLines,
      });
    }
    lines.push({ type: 'ruler' });

    let paymentType = order.cashless ? 'Картка' : 'Готівка';

    // summary
    const summary: any[] = [];

    summary.push({
      name: 'СУМА',
      value: MoneyUtils.money(order.amount) + 'грн',
    });

    if (order.discount > 0) {
      summary.push({
        name: 'ЗНИЖКА',
        value: MoneyUtils.money(order.discount),
      });
      summary.push({
        name: 'ДО СПЛАТИ ',
        value: MoneyUtils.money(order.totalAmount),
      });
    }
    summary.push({
      name: paymentType,
      value: MoneyUtils.money(order.totalAmount),
    });

    lines.push({ type: 'properties', lines: summary });
    lines.push({ type: 'ruler' });

    lines.push({
      type: 'text',
      value: order.opened
        ? DateUtils.display(order.opened)
        : order.created
        ? DateUtils.display(order.created)
        : '',
      align: 'center',
    });

    // delivery
    // if (order.delivery) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: ['Доставка'],
    //     align: 'center',
    //   });

    //   const _values: string[] = [];
    //   if (order.delivery.deliveryMethod) {
    //     _values.push(order.delivery.deliveryMethod.name);
    //   }

    //   let _address1 = '';

    //   if (order.delivery.street) {
    //     _address1 += `ул.${order.delivery.street}`;
    //   }

    //   if (receipt.delivery.houseNumber) {
    //     _address1 += ` д.${receipt.delivery.houseNumber}`;
    //   }

    //   if (receipt.delivery.houseCode) {
    //     _address1 += ` корп.${receipt.delivery.houseCode}`;
    //   }

    //   _values.push(_address1);

    //   let _address2 = '';
    //   if (receipt.delivery.flatNumber) {
    //     _address1 += `кв.${receipt.delivery.flatNumber}`;
    //   }
    //   if (receipt.delivery.floor) {
    //     _address1 += ` этаж ${receipt.delivery.floor}`;
    //   }

    //   _values.push(_address2);

    //   lines.push({
    //     type: 'text',
    //     value: _values,
    //     align: 'left',
    //   });

    //   if (receipt.delivery.deliveryMethod.price) {
    //     lines.push({
    //       type: 'properties',
    //       lines: [
    //         {
    //           name: 'вартість',
    //           value: MoneyUtils.money(receipt.delivery.deliveryMethod.price),
    //         },
    //       ],
    //     });
    //   }

    //   if (receipt.delivery.note) {
    //     lines.push({ type: 'empty' });
    //     lines.push({
    //       type: 'text',
    //       value: receipt.delivery.note,
    //       align: 'center',
    //     });
    //   }
    // }

    // if (receipt.publicNote) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: receipt.publicNote,
    //   });
    // }

    // if (footer) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: [footer],
    //     align: 'center',
    //   });
    // }

    // if (receipt.wish) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: receipt.wish,
    //   });
    // }

    return lines;
  }

  static xmlDocToLines2(xmlString: string, taxCabinetUrl: string): PrintLine[] {
    const doc = OrderUtils.parseXmlDoc(xmlString);
    if (!doc || !doc.CHECK) {
      return;
    }

    const check = doc.CHECK;
    const deviderLine: PrintLine = {
      type: 'ruler',
    };

    const lines: PrintLine[] = [];
    const orgType = check.CHECKHEAD.TIN ? 'ФОП' : 'ТОВ';

    // line 1
    lines.push({
      type: 'text',
      value: `${orgType} ${check.CHECKHEAD.ORGNM}`,
      align: 'center',
    });

    // line 2
    lines.push({
      type: 'text',
      value: check.CHECKHEAD.POINTNM,
    });

    // line 3
    lines.push({
      type: 'text',
      value: check.CHECKHEAD.POINTADDR,
    });

    // line 4
    if (check.CHECKHEAD.IPN) {
      lines.push({
        type: 'text',
        value: `ПН ${check.CHECKHEAD.IPN}`,
        align: 'center',
      });
    }

    // line 5
    if (check.CHECKHEAD.TIN) {
      lines.push({
        type: 'text',
        value: `ІД ${check.CHECKHEAD.TIN}`,
        align: 'center',
      });
    }

    lines.push(deviderLine);

    let products: any = [];

    const CHECKBODY = check.CHECKBODY;
    if (CHECKBODY && CHECKBODY.ROW) {
      if (CHECKBODY.ROW.length) {
        products = CHECKBODY.ROW;
      } else {
        products.push(CHECKBODY.ROW);
      }
    }

    for (let p of products) {
      const productLines = [];

      // line 6
      if (p.AMOUNT != 1) {
        productLines.push({
          name: `${p.AMOUNT}  x  ${MoneyUtils.money(p.PRICE)}`,
          value: '',
        });
      }

      // line 7
      if (p.UKTZED) {
        productLines.push({
          name: p.UKTZED,
          align: 'left',
        });
      }

      // line 8
      // if (p.BARCODE) {
      //   productLines.push({
      //     name: p.UKTZED,
      //   });
      // }

      // line 9
      if (p.EXCISELABELS && p.EXCISELABELS.ROW) {
        let exciseLabels: any[] = [];

        if (p.EXCISELABELS.ROW.length) {
          for (let row of p.EXCISELABELS.ROW) {
            exciseLabels.push(row.EXCISELABEL);
          }
        } else {
          exciseLabels.push(p.EXCISELABELS.ROW.EXCISELABEL);
        }
        productLines.push({
          name: exciseLabels.join(' '),
        });
      }

      // line 10
      // line 11 // назва товару (послуги) / спрощена назва товару (послуги), вартість, літерне позначення ставки ПДВ (рядок 11);
      // TODO: add short name support
      const cost = MoneyUtils.money(p.COST);
      const costCel = p.LETTERS ? `${cost} ${p.LETTERS}` : cost;
      productLines.push({
        name: `${p.NAME}`,
        value: costCel,
      });

      lines.push({
        type: 'properties',
        lines: productLines,
      });
    }

    lines.push(deviderLine);
    let CHECKPAY = [];
    if (check.CHECKPAY.ROW && check.CHECKPAY.ROW.length) {
      CHECKPAY = check.CHECKPAY.ROW;
    } else {
      CHECKPAY = [check.CHECKPAY.ROW];
    }

    let REMAINS = 0;
    let PROVIDED = 0;

    for (let cp of CHECKPAY) {
      if (cp.REMAINS) {
        REMAINS += cp.REMAINS;
      }

      if (cp.PROVIDED) {
        PROVIDED += cp.PROVIDED;
      }

      const _lines: { name: string; value: string }[] = [];
      let isBakCard = false;
      if (cp?.PAYSYS?.ROW) {
        let paySys = [];
        if (cp.PAYSYS.ROW.length) {
          paySys = cp.PAYSYS.ROW;
        } else {
          paySys = [cp.PAYSYS.ROW];
        }

        if (
          !paySys.length &&
          (cp.PAYFORMNM == 'БЕЗГОТІВКОВИЙ' || cp.PAYFORMNM == 'БЕЗГОТІВКОВА')
        ) {
          isBakCard = true;
        }

        for (let ps of paySys) {
          // line 12 // ідентифікатор еквайра та торгівця або інші реквізити, що дають змогу їх ідентифікувати (рядок 12);
          if (ps?.ACQUIRENM) {
            _lines.push({
              name: ' - Еквайр',
              value: ps.ACQUIRENM,
            });
          }
          // line 13 // ідентифікатор платіжного пристрою (рядок 13);
          if (ps?.DEVICEID) {
            _lines.push({
              name: ' - Термінал',
              value: ps.DEVICEID,
            });
          }

          // line 14 // сума комісійної винагороди еквайра (у разі наявності) (рядок 14);
          // line 15 // вид операції (рядок 15);
          // line 16 // реквізити електронного платіжного засобу (платіжної картки) у форматі, що дозволений правилами безпеки емітента або платіжної системи (для електронних платіжних засобів, що використовуються для здійснення операцій у платіжній системі), перед якими друкуються великі літери «ЕПЗ» (рядок 16);
          if (ps?.EPZDETAILS) {
            isBakCard = true;
            _lines.push({
              name: ' - ЕПЗ',
              value: ps.EPZDETAILS,
            });
          }
          // line 17 // напис «ПЛАТІЖНА СИСТЕМА» (найменування платіжної системи, платіжний інструмент якої використовується, код авторизації або інший код, що ідентифікує операцію в платіжній системі та/або код транзакції в платіжній системі, значення коду) (рядок 17);
          if (ps?.NAME) {
            const code = ps.AUTHCD ? `   Код ${ps.AUTHCD.toString()}` : '';
            _lines.push({
              name: ' - ПЛАТІЖНА СИСТЕМА',
              value: `${ps.NAME}${code}`,
            });
          }

          // if (ps.POSTRANSDATE && ps.POSTRANSDATE.length == 14) {
          //   const dateStr = ps.POSTRANSDATE.substring(0, 8);
          //   const timeStr = ps.POSTRANSDATE.substring(8);
          //   const dateString = OrderUtils.getTaxDocumentDateTimeString(
          //     dateStr,
          //     timeStr
          //   );

          //   _lines.push({
          //     name: ' - Дата і час',
          //     value: dateString,
          //   });
          // }
        }
      }

      // line 18 // позначення форми оплати («ГОТІВКА», «БЕЗГОТІВКОВА», «ІНШЕ»), суму коштів за цією формою оплати та валюту операції (рядок 18);
      const paySum = cp.PROVIDED || cp.SUM;

      _lines.push({
        name: cp.PAYFORMNM,
        value: MoneyUtils.money(paySum) + ' грн',
      });

      // line 19 // засоби оплати (вид платіжного інструменту, талон, жетон тощо) (рядок 19);
      if (!check.CHECKHEAD.ORDERRETNUM) {
        if (isBakCard) {
          _lines.push({
            name: 'КАРТА',
            value: '',
          });
        }
      }

      lines.push({
        type: 'properties',
        lines: _lines,
      });
      lines.push(deviderLine);
    }

    // line 20 // загальна вартість придбаних товарів (отриманих послуг) у межах чека, перед якою друкується слово «СУМА» або «УСЬОГО» (рядок 20);
    if (check.CHECKTOTAL.DISCOUNTSUM > 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ЗНИЖКА',
            value: '-' + MoneyUtils.money(check.CHECKTOTAL.DISCOUNTSUM),
          },
        ],
      });
    }

    const checkTotal = check.CHECKTOTAL.NORNDSUM || check.CHECKTOTAL.SUM;
    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'УСЬОГО',
          value: MoneyUtils.money(checkTotal),
        },
      ],
    });

    // line 21 - 22
    if (check.CHECKTAX && check.CHECKTAX.ROW) {
      let taxes = [];
      if (check.CHECKTAX.ROW.length) {
        taxes = check.CHECKTAX.ROW;
      } else {
        taxes = [check.CHECKTAX.ROW];
      }
      for (let tax of taxes) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: `${tax.NAME} ${tax.LETTER}   ${
                tax.PRC
              }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
              value: MoneyUtils.money(tax.SUM),
              align: 'right',
            },
          ],
        });
      }

      lines.push(deviderLine);
    }

    // line 23 заокруглення (рядок 23);
    if (check.CHECKTOTAL.RNDSUM > 0 || check.CHECKTOTAL.RNDSUM < 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ЗАОКРУГЛЕННЯ',
            value: MoneyUtils.money(Math.abs(check.CHECKTOTAL.RNDSUM)),
          },
        ],
      });
    }

    // line 24 // до сплати, валюта (рядок 24);
    if (!check.CHECKHEAD.ORDERRETNUM) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ДО СПЛАТИ',
            value: MoneyUtils.money(check.CHECKTOTAL.SUM) + ' грн',
          },
        ],
      });
    }

    // line 25 // решта, валюта (зазначається у разі здійснення оплати в готівковій формі) (рядок 25);
    if (REMAINS > 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'РЕШТА',
            value: MoneyUtils.money(REMAINS) + ' грн',
          },
        ],
      });
    }

    lines.push(deviderLine);

    // line 26 // фіскальний номер фіскального чека / фіскальний номер електронного фіскального чека, перед яким друкується назва «ЧЕК №» (рядок 26);
    lines.push({
      type: 'text',
      value: `ЧЕК № ${check.CHECKHEAD.ORDERTAXNUM}`,
      align: 'center',
    });

    const dateString = OrderUtils.getTaxDocumentDateTimeString(
      check.CHECKHEAD.ORDERDATE,
      check.CHECKHEAD.ORDERTIME
    );

    // line 27 // дату (день, місяць, рік) та час (година, хвилина, секунда) проведення розрахункової операції (рядок 27);
    lines.push({
      type: 'text',
      value: dateString,
      align: 'center',
    });

    // line 28 // реквізити паспортного документа покупця: серія (за наявності) ..

    // line 29 QR-code
    const checkUrl = this.getTaxCabinetLinkFromXml(xmlString, taxCabinetUrl);
    lines.push({
      type: 'qrcode',
      value: checkUrl,
    });
    lines.push({ type: 'empty' });

    // line 30 // од автентифікації повідомлення (МАС) чека (рядок 30);

    // line 31 - 32 // позначку щодо режиму роботи (офлайн/онлайн), в якому створений фіскальний чек програмним реєстратором розрахункових операцій (рядок 31), контрольне число, сформоване в режимі офлайн (рядок 32);
    const onlineOffline = check.CHECKHEAD.OFFLINE ? 'офлайн' : 'онлайн';
    lines.push({
      type: 'text',
      value: onlineOffline,
      align: 'center',
    });

    // line // 33 // заводський номер реєстратора розрахункових операцій, перед яким друкуються великі літери «ЗН». Заводський номер для програмних реєстраторів розрахункових операцій не зазначається (рядок 33);

    // line 34 // фіскальний номер реєстратора розрахункових операцій, перед яким друкуються великі літери «ФН» або фіскальний номер програмного реєстратора розрахункових операцій, перед яким друкуються великі літери «ФН ПРРО» (рядок 34);
    lines.push({
      type: 'text',
      value: `ФН ПРРО ${check.CHECKHEAD.CASHREGISTERNUM}`,
      align: 'center',
    });

    // line 35 // напис «ФІСКАЛЬНИЙ ЧЕК» та графічне зображення найменування або логотипу виробника (рядок 35).
    let checkType = 'ФІСКАЛЬНИЙ ЧЕК';
    if (check.CHECKHEAD.ORDERRETNUM) {
      checkType = 'ВИДАТКОВИЙ ЧЕК';
    }
    lines.push({
      type: 'text',
      value: checkType,
      align: 'center',
    });
    lines.push({
      type: 'text',
      value: 'TurboPOS',
      align: 'center',
    });
    // line 35 end

    if (check.CHECKHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    }

    return lines;
  }

  static xmlDocToLines(xmlString: string, taxCabinetUrl: string): PrintLine[] {
    const doc = OrderUtils.parseXmlDoc(xmlString);
    if (!doc || !doc.CHECK) {
      return;
    }

    const check = doc.CHECK;
    const deviderLine: PrintLine = {
      type: 'ruler',
    };

    const lines: PrintLine[] = [];
    const orgType = check.CHECKHEAD.TIN ? 'ФОП' : 'ТОВ';

    // line 1
    lines.push({
      type: 'text',
      value: `${orgType} ${check.CHECKHEAD.ORGNM}`,
      align: 'center',
    });

    // line 2
    lines.push({
      type: 'text',
      value: check.CHECKHEAD.POINTNM,
    });

    // line 3
    lines.push({
      type: 'text',
      value: check.CHECKHEAD.POINTADDR,
    });

    // line 4
    if (check.CHECKHEAD.IPN) {
      lines.push({
        type: 'text',
        value: `ПН ${check.CHECKHEAD.IPN}`,
        align: 'center',
      });
    }

    // line 5
    if (check.CHECKHEAD.TIN) {
      lines.push({
        type: 'text',
        value: `ІД ${check.CHECKHEAD.TIN}`,
        align: 'center',
      });
    }

    lines.push(deviderLine);

    let products: any = [];

    const CHECKBODY = check.CHECKBODY;
    if (CHECKBODY && CHECKBODY.ROW) {
      if (CHECKBODY.ROW.length) {
        products = CHECKBODY.ROW;
      } else {
        products.push(CHECKBODY.ROW);
      }
    }

    for (let p of products) {
      const productLines = [];

      // line 6
      if (p.AMOUNT != 1) {
        productLines.push({
          name: `${p.AMOUNT}  x  ${MoneyUtils.money(p.PRICE)}`,
          value: '',
        });
      }

      // line 7
      if (p.UKTZED) {
        productLines.push({
          name: p.UKTZED,
          align: 'left',
        });
      }

      // line 8
      // if (p.BARCODE) {
      //   productLines.push({
      //     name: p.UKTZED,
      //   });
      // }

      // line 9
      if (p.EXCISELABELS && p.EXCISELABELS.ROW) {
        let exciseLabels: any[] = [];

        if (p.EXCISELABELS.ROW.length) {
          for (let row of p.EXCISELABELS.ROW) {
            exciseLabels.push(row.EXCISELABEL);
          }
        } else {
          exciseLabels.push(p.EXCISELABELS.ROW.EXCISELABEL);
        }
        productLines.push({
          name: exciseLabels.join(' '),
        });
      }

      // line 10
      // line 11 // назва товару (послуги) / спрощена назва товару (послуги), вартість, літерне позначення ставки ПДВ (рядок 11);
      // TODO: add short name support
      const cost = MoneyUtils.money(p.COST);
      const costCel = p.LETTERS ? `${cost} ${p.LETTERS}` : cost;
      productLines.push({
        name: `${p.NAME}`,
        value: costCel,
      });

      lines.push({
        type: 'properties',
        lines: productLines,
      });
    }

    lines.push(deviderLine);
    let CHECKPAY = [];
    if (check.CHECKPAY.ROW && check.CHECKPAY.ROW.length) {
      CHECKPAY = check.CHECKPAY.ROW;
    } else {
      CHECKPAY = [check.CHECKPAY.ROW];
    }

    let REMAINS = 0;
    let PROVIDED = 0;

    for (let cp of CHECKPAY) {
      if (cp.REMAINS) {
        REMAINS += cp.REMAINS;
      }

      if (cp.PROVIDED) {
        PROVIDED += cp.PROVIDED;
      }

      const _lines: { name: string; value: string }[] = [];
      let isBakCard = false;
      if (cp?.PAYSYS?.ROW) {
        let paySys = [];
        if (cp.PAYSYS.ROW.length) {
          paySys = cp.PAYSYS.ROW;
        } else {
          paySys = [cp.PAYSYS.ROW];
        }

        if (
          !paySys.length &&
          (cp.PAYFORMNM == 'БЕЗГОТІВКОВИЙ' || cp.PAYFORMNM == 'БЕЗГОТІВКОВА')
        ) {
          isBakCard = true;
        }

        for (let ps of paySys) {
          // line 12 // ідентифікатор еквайра та торгівця або інші реквізити, що дають змогу їх ідентифікувати (рядок 12);
          if (ps?.ACQUIRENM) {
            _lines.push({
              name: ' - Еквайр',
              value: ps.ACQUIRENM,
            });
          }
          // line 13 // ідентифікатор платіжного пристрою (рядок 13);
          if (ps?.DEVICEID) {
            _lines.push({
              name: ' - Термінал',
              value: ps.DEVICEID,
            });
          }

          // line 14 // сума комісійної винагороди еквайра (у разі наявності) (рядок 14);
          // line 15 // вид операції (рядок 15);
          // line 16 // реквізити електронного платіжного засобу (платіжної картки) у форматі, що дозволений правилами безпеки емітента або платіжної системи (для електронних платіжних засобів, що використовуються для здійснення операцій у платіжній системі), перед якими друкуються великі літери «ЕПЗ» (рядок 16);
          if (ps?.EPZDETAILS) {
            isBakCard = true;
            _lines.push({
              name: ' - ЕПЗ',
              value: ps.EPZDETAILS,
            });
          }
          // line 17 // напис «ПЛАТІЖНА СИСТЕМА» (найменування платіжної системи, платіжний інструмент якої використовується, код авторизації або інший код, що ідентифікує операцію в платіжній системі та/або код транзакції в платіжній системі, значення коду) (рядок 17);
          if (ps?.NAME) {
            const code = ps.AUTHCD ? `   Код ${ps.AUTHCD.toString()}` : '';
            _lines.push({
              name: ' - ПЛАТІЖНА СИСТЕМА',
              value: `${ps.NAME}${code}`,
            });
          }

          // if (ps.POSTRANSDATE && ps.POSTRANSDATE.length == 14) {
          //   const dateStr = ps.POSTRANSDATE.substring(0, 8);
          //   const timeStr = ps.POSTRANSDATE.substring(8);
          //   const dateString = OrderUtils.getTaxDocumentDateTimeString(
          //     dateStr,
          //     timeStr
          //   );

          //   _lines.push({
          //     name: ' - Дата і час',
          //     value: dateString,
          //   });
          // }
        }
      }

      // line 18 // позначення форми оплати («ГОТІВКА», «БЕЗГОТІВКОВА», «ІНШЕ»), суму коштів за цією формою оплати та валюту операції (рядок 18);
      const paySum = cp.PROVIDED || cp.SUM;

      _lines.push({
        name: cp.PAYFORMNM,
        value: MoneyUtils.money(paySum) + ' грн',
      });

      // line 19 // засоби оплати (вид платіжного інструменту, талон, жетон тощо) (рядок 19);
      if (!check.CHECKHEAD.ORDERRETNUM) {
        if (isBakCard) {
          _lines.push({
            name: 'КАРТА',
            value: '',
          });
        }
      }

      lines.push({
        type: 'properties',
        lines: _lines,
      });
      lines.push(deviderLine);
    }

    // line 20 // загальна вартість придбаних товарів (отриманих послуг) у межах чека, перед якою друкується слово «СУМА» або «УСЬОГО» (рядок 20);
    if (check.CHECKTOTAL.DISCOUNTSUM > 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ЗНИЖКА',
            value: '-' + MoneyUtils.money(check.CHECKTOTAL.DISCOUNTSUM),
          },
        ],
      });
    }

    const checkTotal = check.CHECKTOTAL.NORNDSUM || check.CHECKTOTAL.SUM;
    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'УСЬОГО',
          value: MoneyUtils.money(checkTotal),
        },
      ],
    });

    // line 21 - 22
    if (check.CHECKTAX && check.CHECKTAX.ROW) {
      let taxes = [];
      if (check.CHECKTAX.ROW.length) {
        taxes = check.CHECKTAX.ROW;
      } else {
        taxes = [check.CHECKTAX.ROW];
      }
      for (let tax of taxes) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: `${tax.NAME} ${tax.LETTER}   ${
                tax.PRC
              }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
              value: MoneyUtils.money(tax.SUM),
              align: 'right',
            },
          ],
        });
      }

      lines.push(deviderLine);
    }

    // line 23 заокруглення (рядок 23);
    if (check.CHECKTOTAL.RNDSUM > 0 || check.CHECKTOTAL.RNDSUM < 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ЗАОКРУГЛЕННЯ',
            value: MoneyUtils.money(Math.abs(check.CHECKTOTAL.RNDSUM)),
          },
        ],
      });
    }

    // line 24 // до сплати, валюта (рядок 24);
    if (!check.CHECKHEAD.ORDERRETNUM) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'ДО СПЛАТИ',
            value: MoneyUtils.money(check.CHECKTOTAL.SUM) + ' грн',
          },
        ],
      });
    }

    // line 25 // решта, валюта (зазначається у разі здійснення оплати в готівковій формі) (рядок 25);
    if (REMAINS > 0) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'РЕШТА',
            value: MoneyUtils.money(REMAINS) + ' грн',
          },
        ],
      });
    }

    lines.push(deviderLine);

    // line 26 // фіскальний номер фіскального чека / фіскальний номер електронного фіскального чека, перед яким друкується назва «ЧЕК №» (рядок 26);
    lines.push({
      type: 'text',
      value: `ЧЕК № ${check.CHECKHEAD.ORDERTAXNUM}`,
      align: 'center',
    });

    const dateString = OrderUtils.getTaxDocumentDateTimeString(
      check.CHECKHEAD.ORDERDATE,
      check.CHECKHEAD.ORDERTIME
    );

    // line 27 // дату (день, місяць, рік) та час (година, хвилина, секунда) проведення розрахункової операції (рядок 27);
    lines.push({
      type: 'text',
      value: dateString,
      align: 'center',
    });

    // line 28 // реквізити паспортного документа покупця: серія (за наявності) ..

    // line 29 QR-code
    const checkUrl = this.getTaxCabinetLinkFromXml(xmlString, taxCabinetUrl);
    lines.push({
      type: 'qrcode',
      value: checkUrl,
    });
    lines.push({ type: 'empty' });

    // line 30 // од автентифікації повідомлення (МАС) чека (рядок 30);

    // line 31 - 32 // позначку щодо режиму роботи (офлайн/онлайн), в якому створений фіскальний чек програмним реєстратором розрахункових операцій (рядок 31), контрольне число, сформоване в режимі офлайн (рядок 32);
    const onlineOffline = check.CHECKHEAD.OFFLINE ? 'офлайн' : 'онлайн';
    lines.push({
      type: 'text',
      value: onlineOffline,
      align: 'center',
    });

    // line // 33 // заводський номер реєстратора розрахункових операцій, перед яким друкуються великі літери «ЗН». Заводський номер для програмних реєстраторів розрахункових операцій не зазначається (рядок 33);

    // line 34 // фіскальний номер реєстратора розрахункових операцій, перед яким друкуються великі літери «ФН» або фіскальний номер програмного реєстратора розрахункових операцій, перед яким друкуються великі літери «ФН ПРРО» (рядок 34);
    lines.push({
      type: 'text',
      value: `ФН ПРРО ${check.CHECKHEAD.CASHREGISTERNUM}`,
      align: 'center',
    });

    // line 35 // напис «ФІСКАЛЬНИЙ ЧЕК» та графічне зображення найменування або логотипу виробника (рядок 35).
    let checkType = 'ФІСКАЛЬНИЙ ЧЕК';
    if (check.CHECKHEAD.ORDERRETNUM) {
      checkType = 'ВИДАТКОВИЙ ЧЕК';
    }
    lines.push({
      type: 'text',
      value: checkType,
      align: 'center',
    });
    lines.push({
      type: 'text',
      value: 'TurboPOS',
      align: 'center',
    });
    // line 35 end

    if (check.CHECKHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    }

    return lines;
  }

  static preOrderPrintLines(order: OrderDTO): PrintLine[] {
    const lines: PrintLine[] = [];
    lines.push({ type: 'empty' });
    lines.push({ type: 'ruler' });
    lines.push({
      type: 'text',
      value: 'ПОПЕРЕДНЕ ЗАМОВЛЕННЯ',
      align: 'center',
    });

    lines.push({ type: 'ruler' });
    lines.push({ type: 'empty' });
    lines.push({ type: 'empty' });

    lines.push({
      type: 'text',
      value: order.storeName,
      align: 'center',
    });

    if (order.pointAddress) {
      lines.push({
        type: 'text',
        value: order.pointAddress,
        align: 'center',
      });
    }
    lines.push({ type: 'empty' });

    const created = order.opened
      ? DateUtils.display(order.opened, false)
      : order.created
      ? DateUtils.display(order.created, false)
      : '';

    const now = DateUtils.display(null, false);

    const headerLines = [
      { name: 'Відкрито', value: created },
      { name: 'Надруковано', value: now },
    ];

    if (order.table) {
      const name = order.table.name ? order.table.name : '';
      const number = order.table.number ? `#${order.table.number}` : '';
      headerLines.push(
        {
          name: 'Зал',
          value: `${order.table.hallName}`,
        },
        {
          name: 'Стіл',
          value: `${name} ${number}`,
        }
      );
    }

    lines.push({
      type: 'properties',
      lines: headerLines,
      align: 'center',
    });
    lines.push({ type: 'empty' });

    lines.push({ type: 'ruler' });
    lines.push({ type: 'text', value: 'Товари', align: 'center' });

    const productLines = [];
    for (let prod of order.orderItems) {
      if (prod.quantity == 1) {
        productLines.push({
          name: prod.name,
          value: `${MoneyUtils.money(prod.amount)}`,
        });
      } else {
        productLines.push({
          name: `${prod.quantity} x ${MoneyUtils.money(prod.price)}`,
        });
        productLines.push({
          name: prod.name,
          value: `${MoneyUtils.money(prod.amount)}`,
        });
      }
    }
    lines.push({ type: 'properties', lines: productLines });
    lines.push({ type: 'ruler' });

    // summary
    const summary: any[] = [];
    summary.push({
      name: 'СУМА',
      value: `${MoneyUtils.money(order.amount)}`,
    });

    if (order.discount > 0) {
      const _loyaltyName = order.loyaltyProgram
        ? 'ЗНИЖКА, ' + order.loyaltyProgram.name
        : 'ЗНИЖКА';

      summary.push({
        name: _loyaltyName,
        value: MoneyUtils.money(order.discount),
      });
    }

    summary.push({
      name: 'ДО СПЛАТИ',
      value: `${order.total_str} грн`,
    });

    lines.push({ type: 'properties', lines: summary });

    if (order.publicNote) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: 'Коментар:',
      });
      lines.push({
        type: 'text',
        value: order.publicNote,
      });
    }

    return lines;
  }

  static orderToPrintLines(
    order: OrderDTO,
    header: string,
    addOrderNumber: boolean
  ): PrintLine[] {
    const lines: PrintLine[] = [];

    if (order.storeName) {
      lines.push({
        type: 'text',
        value: order.storeName,
        align: 'center',
      });
      lines.push({
        type: 'empty',
      });
    }

    if (header) {
      lines.push({
        type: 'text',
        value: header,
        align: 'center',
      });
    }

    if (order.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: DateUtils.formatCustom(order.deadline, 'dd MMM yyyy HH:mm'),
          },
        ],
      });
    }

    if (addOrderNumber ?? order.orderNumber) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Номер замовлення',
            value: order.orderNumber,
          },
        ],
      });
    }

    if (order.customerName) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Клієнт',
            value: order.customerName,
          },
        ],
      });
    }

    lines.push({ type: 'empty' });
    lines.push({ type: 'ruler' });
    const productLines = [];
    for (let prod of order.orderItems) {
      if (prod.quantity == 1) {
        productLines.push({
          name: prod.name,
          value: `${MoneyUtils.money(prod.price)}`,
        });
      } else {
        productLines.push({
          name: `${prod.quantity} х ${MoneyUtils.money(prod.price)}`,
        });

        productLines.push({
          name: prod.name,
          value: MoneyUtils.money(prod.amount),
        });
      }
    }
    lines.push({ type: 'properties', lines: productLines });
    lines.push({ type: 'ruler' });

    const summary: any[] = [];
    summary.push({
      name: 'СУМА',
      value: `${MoneyUtils.money(order.amount)}грн`,
    });

    if (order.discount > 0) {
      summary.push({
        name: 'ЗНИЖКА',
        value: `-${MoneyUtils.money(order.discount)}грн`,
      });

      summary.push({
        name: 'ДО СПЛАТИ',
        value: `${MoneyUtils.money(order.totalAmount)}грн`,
      });
    }

    if (order.totalAmount > order.totalPaid) {
      summary.push({
        name: 'СПЛАЧЕНО',
        value: MoneyUtils.money(order.totalPaid),
      });
      summary.push({
        name: 'ДО СПЛАТИ',
        value: MoneyUtils.money(order.totalAmount - order.totalPaid),
      });
    }

    lines.push({ type: 'properties', lines: summary });
    lines.push({ type: 'ruler' });

    const paymentFormName =
      order.paymentType == 'cash'
        ? 'ГОТІВКА'
        : order.paymentType == 'card'
        ? 'КАРТА'
        : 'ОПЛАТА';
    lines.push({
      type: 'properties',
      lines: [
        {
          name: paymentFormName,
          value: `${MoneyUtils.money(order.totalAmount)}грн`,
        },
      ],
    });

    lines.push({ type: 'empty' });
    const opened = order.opened
      ? DateUtils.display(order.opened)
      : order.created
      ? DateUtils.display(order.created)
      : '';
    lines.push({
      type: 'text',
      value: opened,
      align: 'center',
    });

    if (order.publicNote) {
      lines.push({ type: 'text', value: 'Коментар:' });
      lines.push({
        type: 'text',
        value: order.publicNote,
      });
    }

    // delivery
    let deliveryLines = [];
    if (order.delivery) {
      deliveryLines = this.deliveryToPrintLines(order.delivery);
    }

    return [...lines, ...deliveryLines];
  }

  static deliveryToPrintLines(delivery: any): PrintLine[] {
    const lines: PrintLine[] = [];
    lines.push({ type: 'empty' });
    lines.push({ type: 'ruler' });
    lines.push({ type: 'text', value: 'Доставка', align: 'center' });
    lines.push({ type: 'ruler' });

    const addressLines: any[] = [];

    if (delivery.street) {
      lines.push({ type: 'text', value: `вул. ${delivery.street}` });
    }
    if (delivery.houseNumber) {
      addressLines.push({ name: 'Будинок', value: delivery.houseNumber });
    }
    if (delivery.houseCode) {
      addressLines.push({ name: 'Корпус', value: delivery.houseCode });
    }
    if (delivery.flatNumber) {
      addressLines.push({ name: 'Квартира', value: delivery.flatNumber });
    }
    if (delivery.floor) {
      addressLines.push({ name: 'Поверх', value: delivery.floor });
    }
    lines.push({ type: 'properties', lines: addressLines });

    lines.push({ type: 'ruler' });
    if (delivery.deliveryMethod) {
      lines.push({ type: 'text', value: delivery.deliveryMethod.name });
    }
    if (delivery.deliveryMethod.price) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'вартість',
            value: MoneyUtils.money(delivery.deliveryMethod.price),
          },
        ],
      });
    }

    if (delivery.note) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: 'Коментар:',
      });
      lines.push({
        type: 'text',
        value: delivery.note,
      });
    }

    return lines;
  }

  static workAreasToPrintLines(
    order: OrderDTO,
    workAreas: any[]
  ): { workAreaId: number; printLines: PrintLine[] }[] {
    const result: { workAreaId: number; printLines: PrintLine[] }[] = [];

    var productsByWorkArea: { [key: string]: { products: any[] } } =
      order.orderItems.reduce((prev, current) => {
        if (!current.workAreaId) {
          return prev;
        }

        if (!prev[current.workAreaId]) {
          prev[current.workAreaId] = { products: [] };
        }

        prev[current.workAreaId].products.push(current);
        return prev;
      }, {});

    const workAreasIds = Object.keys(productsByWorkArea);
    for (let waId of workAreasIds) {
      const item = { workAreaId: +waId, printLines: [] };
      const workArea = workAreas.find((wa) => wa.id == waId);
      const lines: PrintLine[] = [];

      lines.push({ type: 'empty' });
      if (order.storeName) {
        lines.push({
          type: 'text',
          value: order.storeName,
          align: 'center',
        });
      }

      if (workArea?.name) {
        lines.push({
          type: 'text',
          value: workArea.name,
          align: 'center',
        });
      }

      if (order.id > 0) {
        lines.push({
          type: 'text',
          value: `Замовлення № ${order.id}`,
        });
      }

      if (order.deadline) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'Дата гот',
              value: order.deadline
                ? DateUtils.formatCustom(order.deadline, 'dd MMM yyyy HH:mm')
                : '',
            },
          ],
        });
      }

      if (order.table) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'Зал:',
              value: `${order.table.hallName}`,
            },
            {
              name: 'Стіл:',
              value: `#${order.table.number || ''} ${order.table.name || ''}`,
            },
          ],
        });
      }

      lines.push({ type: 'ruler' });
      const areaProducts = productsByWorkArea[waId].products;
      const productLines = [];
      for (let prod of areaProducts) {
        productLines.push({
          name: prod.name,
          value: prod.quantity.toFixed(2),
        });
      }
      lines.push({ type: 'properties', lines: productLines });
      lines.push({ type: 'ruler' });

      if (order.note) {
        lines.push({
          type: 'text',
          value: 'Коментар:',
        });
        lines.push({
          type: 'text',
          value: order.note,
        });
      }

      const date = order.opened
        ? DateUtils.display(order.opened, false)
        : order.created
        ? DateUtils.display(order.created, false)
        : '';
      lines.push({
        type: 'text',
        value: date,
        align: 'center',
      });

      lines.push({ type: 'empty' });
      lines.push({ type: 'empty' });

      item.printLines = lines;
      result.push(item);
    }

    return result;
  }
}
