import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Device } from '@ionic-native/device/ngx';
import { AuthService } from './auth.service';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';
import { ApiV1Service } from './api-v1.service';

const CURRENT_DEVICE_ID = 'CURRENT_DEVICE_ID';

@Injectable({
  providedIn: 'root',
})
export class CompanyDeviceService {
  private get baseUrl() {
    return `${this._settingsService.api_endpoint}/devices`;
  }

  constructor(
    private _settingsService: SettingsService,
    private device: Device,
    private storage: StorageService,
    private _authService: AuthService,
    private _api: ApiV1Service
  ) {}

  installDevice(): Observable<any> {
    if (!this.device || !this.device.uuid) {
      console.log('ionic device is not provided');
    }

    let deviceUuid = this.device.uuid;
    return this._api
      .registerTerminal(
        deviceUuid,
        this.device.isVirtual,
        this.device.model,
        this.device.serial
      )
      .pipe(
        mergeMap((device: any) => {
          return from(this.storage.set(CURRENT_DEVICE_ID, device.id));
        })
      );
  }

  installedDeviceId = (): Promise<string> =>
    this.storage.get(CURRENT_DEVICE_ID);

  clearDeviceId = (): Observable<string> =>
    from(this.storage.remove(CURRENT_DEVICE_ID));
}
