import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject, forkJoin } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersRepository } from '../repositories/customers.repository';
import {
  EmployeeItem,
  EmployeeRepository,
} from '../repositories/employee.repository';
import { WorkAreasRepository } from '../repositories/workarea.repository';
import { EmployeeService, IEmployeeSession } from './employee.service';
import { CompanyService } from '../../tenants/company.service';
import { TerminalDataService } from './terminal-data.service';
import { CategoriesRepository } from '../repositories/categories.repository';
import { ProductsRepository } from '../repositories/products.repository';
import { WorkShiftDialogComponent } from '../components/work-shift/work-shift-dialog.component';
import { OrdersBackupRepository } from '../repositories/orders-backup.repository';
import { TerminalSessionNewComponent } from '../terminal-session-new.component';
import { SettingsService } from 'src/app/services/settings.service';
import DateUtils from 'src/app/utility/date-utils';
import { StorageService } from 'src/app/services/storage.service';
import {
  TerminalAuthService,
  TerminalSession,
} from 'src/app/services/terminal-auth.service';
import { CompanyStorage } from 'src/app/_core/company-storage';
import { CompanyDataService } from 'src/app/modules/company/company-data.service';
import { FeatureService } from 'src/app/services/features.service';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { PromosRepository } from '../repositories/promos.repository';
import { HallsRepository } from '../repositories/halls.repository';
import { TenantAuthService } from 'src/app/services/tenant-auth.service';

class Cashbox {
  name: string;
  storeId: number;
  id: number;
}

class CashboxSession {
  account_id: number;
  cashbox: Cashbox;
  cashbox_id: number;
  collection_accountId: number;
  id: number;
  start: Date;
  start_amount: number;
  start_by: string;
  uuid: string;
  end: Date;
  end_by: string;
  end_amount: number;
  comment: string;
  changed?: boolean;

  constructor(
    startAmount: number,
    employeeId: string,
    account_id: number,
    collection_accountId: number,
    comment: string = null
  ) {
    this.id = 0;
    this.uuid = UUID.UUID();
    this.start_by = employeeId;
    this.start = new Date();
    this.start_amount = startAmount;
    this.account_id = account_id;
    this.collection_accountId = collection_accountId;
    this.comment = comment;
  }
}

const TERMINAL_SESSIONS_KEY = '__terminal_sessions_';
const CASHBOX_SESSIONS_KEY = '__cashbox_sessions_';
const LAST_UPDATED_CACHE = 'LAST_UPDATED_CACHE';

@Injectable({ providedIn: 'root' })
export class TerminalClientService {
  private get terminalBaseUrl(): string {
    return `${this._settingsService.api_endpoint}/pos/terminal`;
  }

  private _terminalSessionsSubject = new BehaviorSubject<TerminalSession[]>([]);
  private _terminalSessions: Observable<TerminalSession[]>;

  private _cashboxSessionsSubject = new BehaviorSubject<CashboxSession[]>([]);
  private _cashboxSessions: Observable<CashboxSession[]>;
  currentCashboxSession = new BehaviorSubject<CashboxSession>(null);

  public terminalState: Observable<string>;
  private _currentEmployeeSession = new BehaviorSubject<IEmployeeSession>(null);
  get currentEmployeeSession() {
    return this._currentEmployeeSession.asObservable();
  }
  get currentSession() {
    return this._currentEmployeeSession.value;
  }

  onPromos = new EventEmitter<any[]>();
  onCustomers = new EventEmitter<any[]>();

  constructor(
    private _storage: StorageService,
    private _router: Router,
    private _plt: Platform,
    private _http: HttpClient,
    private _settingsService: SettingsService,
    private _authService: AuthService,
    private _customersRepo: CustomersRepository,
    private _employeeRepo: EmployeeRepository,
    private _employeeService: EmployeeService,
    private _workAreaRepo: WorkAreasRepository,
    private _companyService: CompanyService,
    private _terminalDataService: TerminalDataService,
    private _categoryRepo: CategoriesRepository,
    private _productRepository: ProductsRepository,
    private _backupRepository: OrdersBackupRepository,
    private terminalAuthService: TerminalAuthService,
    private _modalCtrl: ModalController,
    private _companyStorage: CompanyStorage,
    private _companyDataService: CompanyDataService,
    private featureService: FeatureService,
    private api: ApiV1Service,
    private _promosRepository: PromosRepository,
    private _hallsRepository: HallsRepository,
    private _tenantAuth: TenantAuthService
  ) {
    this._loadStoredData();
  }

  async checkCache() {
    const lastUpdate = await this.api.checkData().toPromise();
    const cache = await this._companyStorage.get(LAST_UPDATED_CACHE);

    if (!cache || cache.promos != lastUpdate.promos) {
      this.api.getPromos().subscribe(
        (res) => {
          this._promosRepository.loadPromos(res);
          this.onPromos.emit(res);
        },
        () => {}
      );
    }

    if (!cache || cache.customers != lastUpdate.customers) {
      this.api.getCustomers().subscribe(
        (res) => {
          this._customersRepo.loadCustomers(res);
          this.onCustomers.emit(res);
        },
        () => {}
      );
    }

    if (!cache || cache.halls != lastUpdate.halls) {
      const storeId = this._terminalDataService.terminalSettings().store.id;
      this.api.getHalls(storeId).subscribe(
        (res) => {
          this._hallsRepository.loadHalls(res);
        },
        () => {}
      );
    }

    // if (!cache || cache.users != lastUpdate.users) {
    //   this.api.getUsers().subscribe(
    //     (res) => {
    //       this._hallsRepository.loadHalls(res);
    //     },
    //     () => {}
    //   );
    // }

    await this._companyStorage.set(LAST_UPDATED_CACHE, lastUpdate);
  }

  getPromos() {
    return this._promosRepository.getPromos();
  }

  getHalls() {
    return this._hallsRepository.getHalls();
  }

  private _startTerminalSession(
    employee: EmployeeItem,
    storeId: number
  ): Observable<TerminalSession> {
    const session = new TerminalSession(employee.userid, storeId);

    console.log('Start terminal session');
    console.log('SessionId: ' + session.uuid);
    console.log('EmployeeId: ' + session.employee_id);

    return this._terminalSessions.pipe(
      mergeMap((sessions: TerminalSession[]) => {
        if (!sessions) {
          sessions = [session];
        } else {
          console.log('Sessions found: ' + sessions.length);
          this._endAllTerminalSessions(sessions);
          sessions.push(session);
        }
        return from(this._storage.set(TERMINAL_SESSIONS_KEY, sessions)).pipe(
          map(() => {
            console.log('Sessions stored');
            return session;
          })
        );
      })
    );
  }

  async getEmployeeSession(): Promise<IEmployeeSession> {
    console.log('getEmployeeSession');
    if (this.currentSession) {
      console.log('found');
      return this.currentSession;
    }

    const loggedInEmployee = this.getLoggedInEmployee2();
    const modal = await this._modalCtrl.create({
      component: TerminalSessionNewComponent,
      cssClass: 'terminal-session-modal',
      componentProps: {
        openActive: true,
        employeeId: loggedInEmployee.userid,
        employeeName: loggedInEmployee.full_name,
        storeId: this._terminalDataService.terminalSettings().store.id,
      },
      backdropDismiss: false,
    });

    modal.present();
    const { data, role } = await modal.onWillDismiss();
    this._currentEmployeeSession.next(data);
    this._employeeService.updateEmloyeeSession(data);

    return data;
  }

  isTerminalSynced(): Observable<boolean> {
    return this._getChangedData().pipe(
      map((res) => {
        return (
          //res.terminalSessions.length == 0 &&
          res.employeeSessionsSynced && res.cashboxSessions.length == 0
        );
      })
    );
  }

  endSession(): Observable<void> {
    return this._terminalSessions.pipe(
      mergeMap((sessions: TerminalSession[]) => {
        if (!sessions) {
          return of({});
        }

        this._endAllTerminalSessions(sessions);
        return from(this._storage.set(TERMINAL_SESSIONS_KEY, sessions));
      })
    );
  }

  logout(): Observable<void> {
    return this.isTerminalSynced().pipe(
      mergeMap((res) => {
        if (!res) {
          if (
            confirm(
              'На вашому пристрої є несинхронізовані данні. Після виходу, ви не зможете їх відновити.'
            )
          ) {
            this._clearCompany();
            return this._authService.logout();
          } else {
            return of(null);
          }
        } else {
          this._clearCompany();
          return this._authService.logout();
        }
      })
    );
  }

  unlockTerminal(employee: EmployeeItem): Observable<boolean> {
    console.log('unlockTerminal');
    const settings = this._terminalDataService.terminalSettings();
    const store = settings.store;
    return this._startTerminalSession(employee, store.id).pipe(
      mergeMap((terminalSession) => {
        terminalSession.employee = employee;
        this.terminalAuthService.startTerminalSession(terminalSession);
        console.log(
          'Terminal session started. SessionId: ' + terminalSession.uuid
        );

        return forkJoin([
          // this.getEmployeeSession(),
          // this._employeeService.getActiveSession(employee.userid),
          this._getActiveCashboxSession(),
        ]).pipe(
          mergeMap((result) => {
            // const employeeSession = result[0];
            const cashboxSession = result[0];
            this.currentCashboxSession.next(cashboxSession);

            if (!cashboxSession && settings.terminal.use_cashbox_shifts) {
              this._router.navigate(['/start-session'], {
                replaceUrl: true,
              });
              return of(false);
            }

            // if (!employeeSession) {
            //   if (
            //     this._terminalSettings.terminal.manual_user_session_start
            //   ) {
            //     this._router.navigate(['/start-session'], {
            //       replaceUrl: true,
            //     });
            //     return of(false);
            //   } else {
            //     return this._employeeService
            //       .startSession(
            //         this._currentSessionSub.value.employee_id,
            //         this._terminalSettings.store.id
            //       )
            //       .pipe(
            //         map((newSession) => {
            //           this._snackbar.open('Початок зміни', '', {
            //             duration: 5000,
            //             horizontalPosition: 'left',
            //             verticalPosition: 'top',
            //           });
            //           this._currentEmployeeSession.next(newSession);
            //           return true;
            //         })
            //       );
            //   }
            // }

            // this._currentEmployeeSession.next(employeeSession);
            return of(true);
          })
        );
      })
    );
  }

  async startEmployeeSession(): Promise<IEmployeeSession> {
    const loggedInEmployee = this.getLoggedInEmployee2();
    const settings = this._terminalDataService.terminalSettings();
    return this._employeeService
      .startSession(loggedInEmployee.userid, settings.store.id)
      .then((employeeSession) => {
        this._currentEmployeeSession.next(employeeSession);
        return employeeSession;
      });
  }

  getActiveCashboxSession() {
    return this._getActiveCashboxSession();
  }

  lockTerminal() {
    this._logoutEmployee();
    this._backupRepository.removeOldOrdersFromBackup();

    return this.endSession().pipe(
      map(() => {
        this._router.navigate(['/lock-screen'], { replaceUrl: true });
        return of(null);
      })
    );
  }

  unlinkTerminal() {
    this._clearCompany();
    this._router.navigate(['/companies'], { replaceUrl: true });
  }

  private _clearCompany() {
    console.log('_clearCompany');

    this._companyService.companyLogout().subscribe();
    this._storage.remove(TERMINAL_SESSIONS_KEY);
    this._employeeService.removeSessions().subscribe();
    this._logoutEmployee();
    this._terminalDataService.clearSettings();
    this._storage.remove(CASHBOX_SESSIONS_KEY);
    this._categoryRepo.clearStorage();
    this._customersRepo.clearStorage();
    this._employeeRepo.clearStorage();
    this._productRepository.clearStorage();
    this._workAreaRepo.clearStorage();

    this._cashboxSessionsSubject.next(null);
    this.currentCashboxSession.next(null);
    this._companyStorage.clearCompanyData();
    this._companyDataService.clearCompanyData();
    this._tenantAuth.removeTenant();
  }

  loadTerminalSettings(): Observable<any> {
    this._companyStorage.remove(LAST_UPDATED_CACHE);

    return this._settingsService.getSystemConfig().pipe(
      mergeMap(() =>
        from(this._terminalDataService.getTerminalSettingsNew()).pipe(
          mergeMap((settings) => {
            if (settings === null || settings === undefined) {
              return of();
            }

            return forkJoin([
              this._loadCashboxSessions(),
              this._employeeService.loadSessions(
                settings.store.terminal_sessions
              ),
              from(this._companyService.useConnectionToken(settings.token)),
              from(this._workAreaRepo.loadWorkAreas(settings.store.work_areas)),
              from(this._customersRepo.loadCustomers(settings.customers)),
              from(this._employeeRepo.loadEmployees(settings.employees)),
              from(
                this._categoryRepo.loadCategories(settings.store.categories)
              ),
              from(
                this._productRepository.loadProducts(settings.store.products)
              ),
              from(this.featureService.refreshFeatures()),
            ]);
          }),
          catchError((error: HttpErrorResponse) => {
            // if (error.status === 404) this.deviceService.createDevice(this.device);

            // settings from storage
            alert('Помилка синхронізації');
            const settings = this._terminalDataService.terminalSettings();
            return of(settings);
          })
        )
      )
    );
  }

  private _loadStoredData() {
    const platformObs = from(this._plt.ready());
    this._terminalSessions = platformObs.pipe(
      switchMap(() => {
        return from(this._storage.get(TERMINAL_SESSIONS_KEY));
      }),
      map((sessions: TerminalSession[]) => {
        if (sessions) {
          this._terminalSessionsSubject.next(sessions);
          return sessions;
        } else {
          return [];
        }
      })
    );

    this.terminalState = platformObs.pipe(
      switchMap(() => {
        return this.isTerminalConfigured().pipe(
          map((state) => {
            if (!state) {
              return 'companies';
            } else {
              if (this.getLoggedInEmployee2()) {
                return 'terminal';
              } else {
                return 'lock-screen';
              }
            }
          })
        );
      })
    );

    this._cashboxSessions = platformObs.pipe(
      switchMap(() => {
        return from(this._storage.get(CASHBOX_SESSIONS_KEY));
      }),
      map((sessions: CashboxSession[]) => {
        if (sessions) {
          this._cashboxSessionsSubject.next(sessions);
          return sessions;
        } else {
          return [];
        }
      })
    );
  }

  isTerminalConfigured(): Observable<boolean> {
    const settings = this._terminalDataService.terminalSettings();
    return of(settings).pipe(
      map((res) => {
        if (res !== null && res !== undefined) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  terminalReady(): Observable<boolean> {
    const settings = this._terminalDataService.terminalSettings();
    if (settings == null) {
      // clear settings
      this._router.navigate(['/company'], { replaceUrl: true });
      return of(false);
    }

    const terminalSession = this.terminalAuthService.getTerminalSession();
    if (terminalSession == null) {
      this._router.navigate(['/lock-screen'], { replaceUrl: true });
      return of(false);
    }

    return of(true);
  }

  private _storeCashboxSessions(sessions: CashboxSession[]): Observable<void> {
    return from(this._storage.set(CASHBOX_SESSIONS_KEY, sessions));
  }

  startCashboxSession(
    startAmount: number,
    comment?: string
  ): Observable<CashboxSession> {
    const loggedInEmployee = this.getLoggedInEmployee2();
    const settings = this._terminalDataService.terminalSettings();
    const store = settings.store;
    return this._startCashboxSession(
      startAmount,
      loggedInEmployee.userid,
      store.cash_account_id,
      store.collection_account_id,
      comment
    );
  }

  closeCashboxSession(endAmount: number, comment?: string): Observable<void> {
    return this.getActiveCashboxSession().pipe(
      mergeMap((session) => {
        if (!session) {
          return of(null);
        } else {
          const loggedInEmployee = this.getLoggedInEmployee2();
          return this._closeCashboxSession(
            session.uuid,
            endAmount,
            loggedInEmployee.userid,
            comment
          ).pipe(mergeMap(() => this._employeeService.closeAllOpenSessions()));
        }
      })
    );
  }

  closeCurrentEmployeeSession(): Promise<any> {
    const employee = this.getLoggedInEmployee();
    return this._employeeService
      .closeSession(this.currentSession.uuid)
      .then(() => {
        this._currentEmployeeSession.next(null);
        return this.lockTerminal();
      });
  }

  async closeEmployeeSession(sessionUuid: string): Promise<any> {
    const employee = this.getLoggedInEmployee();
    return this._employeeService.closeSession(sessionUuid).then(() => {
      this._currentEmployeeSession.next(null);
      return of(); // this.lockTerminal();
    });
  }

  syncTerminalData(): Observable<any> {
    return forkJoin([
      this._employeeService.syncEmployeeSessions(),
      this._syncCashboxSessions(),
    ]);
  }

  private _logoutEmployee() {
    this._currentEmployeeSession.next(null);
    this.terminalAuthService.endTerminalSession();
  }

  get loggedInEmployee(): EmployeeItem {
    return this.getLoggedInEmployee2();
  }

  get isOwner(): boolean {
    const loggedInEmployee = this.terminalAuthService.getTerminalSession();
    return loggedInEmployee != null && loggedInEmployee.employee.is_owner;
  }

  getLoggedInEmployee(): Observable<EmployeeItem> {
    const session = this.terminalAuthService.getTerminalSession();
    if (!session) {
      this.lockTerminal().subscribe();
      return null;
    }

    return of(session.employee);
  }

  getLoggedInEmployee2(): EmployeeItem {
    const session = this.terminalAuthService.getTerminalSession();
    if (!session) {
      this.lockTerminal().subscribe();
      return null;
    }

    return session.employee;
  }

  getTerminalSession(): EmployeeItem {
    return this.terminalAuthService.getTerminalSession();
  }

  private _closeCashboxSession(
    sessionId: string,
    endAmount: number,
    employeeId: string,
    comment: string
  ): Observable<boolean> {
    return this._cashboxSessions.pipe(
      mergeMap((sessions: CashboxSession[]) => {
        let index = -1;
        const session = sessions.find((s, i) => {
          if (s.uuid == sessionId) {
            index = i;
            return true;
          }
        });
        if (!session) {
          return of(false);
        }

        session.end_amount = endAmount;
        session.comment = comment;
        session.end = new Date();
        session.end_by = employeeId;
        session.changed = true;

        sessions.splice(index, 1);
        sessions.splice(index, 0, session);
        this._cashboxSessionsSubject.next(null);

        return this._storeCashboxSessions(sessions).pipe(
          map(() => {
            this._syncCashboxSessions().subscribe(
              () => {},
              () => {
                debugger;
              }
            );
            return true;
          })
        );
      })
    );
  }

  private _getActiveCashboxSession(): Observable<CashboxSession> {
    return this._cashboxSessions.pipe(
      map((sessions) => {
        const activeSessions = sessions.filter((s) => !s.end);
        if (activeSessions.length === 0) {
          return null;
        }

        return activeSessions[0];
      })
    );
  }

  private _loadCashboxSessions() {
    const settings = this._terminalDataService.terminalSettings();
    const remoteCashboxSession = settings.cashbox_shift;
    return this._updateCashboxSession(remoteCashboxSession);
  }

  private _updateCashboxSession(model: CashboxSession) {
    if (!model) {
      return of(null);
    }

    return this._cashboxSessions.pipe(
      mergeMap((sessions) => {
        if (sessions && sessions.length > 0) {
          let index = -1;
          const session = sessions.find((s, i) => {
            if (s.uuid == model.uuid || s.id == model.id) {
              index = i;
              return true;
            }
          });

          session.id = model.id;
          session.start = model.start;
          session.start_amount = model.start_amount;
          session.end = model.end;
          session.end_amount = model.end_amount;
          session.changed = true;

          if (!session.uuid && model.uuid) {
            session.uuid = model.uuid;
          }

          if (session && index > -1) {
            sessions.splice(index, 1);
            sessions.splice(index, 0, session);
          }
        } else {
          sessions = [model];
        }

        return this._storeCashboxSessions(sessions);
      })
    );
  }

  private _startCashboxSession(
    startAmount: number,
    employeeId: string,
    accointId: number,
    collectionAccointId: number,
    comment: string
  ): Observable<CashboxSession> {
    console.log('Start cashbox session');

    return this._getActiveCashboxSession().pipe(
      mergeMap((s) => {
        if (s) {
          console.warn(
            'Error! You can not start new session. Active session detected'
          );
          return null;
        } else {
          return this._cashboxSessions.pipe(
            mergeMap((sessions: CashboxSession[]) => {
              const session = new CashboxSession(
                startAmount,
                employeeId,
                accointId,
                collectionAccointId,
                comment
              );
              session.changed = true;

              if (!sessions) {
                sessions = [session];
              } else {
                sessions.push(session);
              }

              this.currentCashboxSession.next(session);
              return this._storeCashboxSessions(sessions).pipe(
                map(() => {
                  console.log('Sessions stored');
                  return session;
                })
              );
            })
          );
        }
      }),
      map((res) => {
        this._syncCashboxSessions().subscribe(
          () => {},
          () => {
            debugger;
          }
        );

        return res;
      })
    );
  }

  private _endAllTerminalSessions(sessions: TerminalSession[]) {
    console.log('End all sessions');
    if (!sessions || sessions.length === 0) {
      return;
    }
    sessions.forEach((session) => {
      if (session.end == null || !session.end) {
        session.end = new Date();
        session.changed = true;
        console.log('End session. SessionId: ' + session.uuid);
      }
    });
  }

  private _getChangedData(): Observable<{
    employeeSessionsSynced: boolean;
    terminalSessions: TerminalSession[];
    cashboxSessions: CashboxSession[];
  }> {
    return forkJoin([
      this._employeeService.isDataSynced,
      this._getChangedTerminalSessions(),
      this._getChangedCashboxSessions(),
    ]).pipe(
      map((res) => {
        return {
          employeeSessionsSynced: res[0],
          terminalSessions: res[1],
          cashboxSessions: res[2],
        };
      })
    );
  }

  private _getChangedCashboxSessions(): Observable<CashboxSession[]> {
    return this._cashboxSessions.pipe(
      map((res) => res.filter((s) => s.changed))
    );
  }

  private _getChangedTerminalSessions(): Observable<TerminalSession[]> {
    return this._terminalSessions.pipe(
      map((res) => res.filter((s) => s.changed))
    );
  }

  private _syncCashboxSessions(): Observable<void> {
    return this._getChangedCashboxSessions().pipe(
      mergeMap((changedSessions) => {
        changedSessions.forEach((s: any) => {
          if (s.end) {
            s.end = DateUtils.format(s.end);
          }

          if (s.start) {
            s.start = DateUtils.format(s.start);
          }
        });
        return this._http
          .put<any>(
            `${this.terminalBaseUrl}/sync-cashbox-sessions`,
            changedSessions
          )
          .pipe(
            mergeMap((result) =>
              this._cashboxSessions.pipe(
                mergeMap((allSessions) => {
                  const uuids = Object.keys(result);
                  uuids.forEach((uuid) => {
                    const session = allSessions.find((s) => s.uuid == uuid);
                    if (session) {
                      session.id = result[uuid];
                      session.changed = false;
                    }
                  });
                  return this._storeCashboxSessions(allSessions);
                })
              )
            )
          );
      })
    );
  }

  getEmployeeSessionReport() {
    console.log('getEmployeeSessionReport');
    return this.getEmployeeSession().then((employeeSession) => {
      if (!employeeSession) {
        return null;
      }

      console.log(employeeSession);
      return this._http
        .get(`${this.terminalBaseUrl}/employeeReport/${employeeSession.uuid}`)
        .toPromise();
    });
  }

  async displayEmployeeSession(): Promise<void> {
    const modal = await this._modalCtrl.create({
      component: WorkShiftDialogComponent,
      cssClass: 'work-shifts-modal',
      componentProps: {
        closeSessionAction: () => this.closeCurrentEmployeeSession(),
        getSessionAction: () => this.getEmployeeSession(),
        getSessionReportAction: () => this.getEmployeeSessionReport(),
      },
      backdropDismiss: false,
    });

    modal.present();
  }
}
