import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import { catchError, map, mergeMap } from 'rxjs/operators';

export enum APP_CONFIG {
  FISCAL_REQUIRED = 'fiscal_required',
  ORDER_QUEUE_RETRY = 'order_queue_retry',
  SYNC_ACRHIVE_PERIOD = 'sync_archive_period_seconds',
  SYNC_START_ORDER = 'sync_start_order_queue',
  ORDER_QUEUE = 'order_queue',
  ORDER_QUEUE_CLOUD = 'order_queue_cloud',
  CHECK_ORDER_API = 'order_api',
  ORDER_SYNC_RETRY_API = 'order_sync_retry_api',
  ORDER_API = 'order_api',
  ORDER_SEND_API = 'order_send_api',
  ORDER_CHECK_API = 'order_check_api',
  PAYMENT_PROCESS_TYPE = 'payment_process_type',
  ACRHIVE_ORDERS_COUNT = 'archive_orders_count',
  TAX_CHECK_URL = 'tax_check_url',
  TAX_API_URL = 'tax_api_url',
  LOGGER_API_URL = 'logger_api_url',
  DEVICE_API_URL = 'device_api_url',
  TEST_TAX_SERVER = 'testTaxServer',
  CURRENT_VERSION = 'current_version',
  CURRENT_VERSION_IOS = 'current_version_ios',
  UPDATE_REQUIRED = 'update_required',
  HAS_TAX_TEST_SHIFT = 'has_tax_test_shift',
  SYNC_ORDER_TIMEOUT = 'sync_order_timeout',
  CHECK_CLOSED_ORDER_TIMEOUT = 'check_closed_order_timeout',
  FISCAL_SERVICE_OPERATIONS = 'fiscal_service_operations',
  API_ENDPOINT = 'api_endpoint',
  POS_API_ENDPOINT = 'pos_api_endpoint',
  CHECK_TERMINAL_DATA_ENDPOINT = 'CHECK_TERMINAL_DATA_ENDPOINT',
  CHECK_CLOSED_ORDERS = 'check_closed_orders',
  ORDER_SYNC_INTERVAL = 'order_sync_interval',
  ORDER_SYNC_CHECKS = 'order_sync_checks',
  EXPIRENZA_API = 'expirenza_api',
}

const _defaultConfig = {
  api_endpoint: 'http://api.turbopos.net/api/v1',
  signal_r_endpoint: 'http://api.turbopos.net/signalr/orderhub',
  [APP_CONFIG.POS_API_ENDPOINT]: 'https://nt-pos-api.azurewebsites.net/api/',
  [APP_CONFIG.CHECK_TERMINAL_DATA_ENDPOINT]:
    'https://nt-pos-api-2.azurewebsites.net/api',
  fs_tax_doc: 'http://fs.tax.gov.ua:8609/fs/doc',
  fs_tax_pck: 'http://fs.tax.gov.ua:8609/fs/pck',
  fs_tax_cmd: 'http://fs.tax.gov.ua:8609/fs/cmd',
  logger_api:
    'http://nt-log-manager.azurewebsites.net/api/item?code=aah3OufGEeBhlHPxn_l6OS5vzMCcW_mrmGIpE2LfDASvAzFusJLX9g%3D%3D',
  order_queue:
    'http://ord-api.turbopos.net/api/order?code=rAe2hLBTNO_Awv7ShvzD8Pb3ROVSjHglKqQR8gVaiGPszCMJEAzFufjlgWA==',
  order_queue_retry:
    'http://ord-api.turbopos.net/api/order/retry?code=rAe2hLBTNO_Awv7ShvzD8Pb3ROVSjHglKqQR8gVaiGPszCMJEAzFufjlgWA==',
  order_queue_cloud:
    'https://nt-order-manager.azurewebsites.net/api/create?code=rAe2hLBTNO_Awv7ShvzDPb3ROVSKqQR8gVaiGPszCMJEAzFufjlgWA%3D%3D',
  [APP_CONFIG.ORDER_SEND_API]: '',
  [APP_CONFIG.ORDER_CHECK_API]: '',
  display_demo: false,
  [APP_CONFIG.TAX_CHECK_URL]: 'https://cabinet.tax.gov.ua/cashregs/check',
  [APP_CONFIG.TAX_API_URL]: 'http://tax-api.turbopos.net/api',
  [APP_CONFIG.LOGGER_API_URL]: 'http://lg.turbopos.net/api',
  [APP_CONFIG.DEVICE_API_URL]: 'https://device-api.turbopos.net',
  [APP_CONFIG.SYNC_ACRHIVE_PERIOD]: 1800,
  [APP_CONFIG.SYNC_START_ORDER]:
    'https://ord-api.turbopos.net/api/order/syncStart',
  [APP_CONFIG.PAYMENT_PROCESS_TYPE]: 'sync_order_with_verify',
  [APP_CONFIG.ACRHIVE_ORDERS_COUNT]: 300,
  store_api: 'https://store-api.turbopos.net/api',
  [APP_CONFIG.TEST_TAX_SERVER]: false,
  [APP_CONFIG.SYNC_ORDER_TIMEOUT]: 5000,
  [APP_CONFIG.FISCAL_SERVICE_OPERATIONS]: false,
  [APP_CONFIG.CHECK_CLOSED_ORDERS]: true,
  [APP_CONFIG.ORDER_SYNC_INTERVAL]: 10000,
  [APP_CONFIG.ORDER_SYNC_CHECKS]: 10,
  [APP_CONFIG.CHECK_CLOSED_ORDER_TIMEOUT]: 3,
  [APP_CONFIG.EXPIRENZA_API]: 'API',
};

// const fs_tax_cmd = 'http://fs.tax.gov.ua:8609/fs/cmd';
// const fs_tax_cmd = 'https://fs.tax.gov.ua:8643/fs/cmd';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _protocol = window.location.protocol;
  private get _configApiUrl() {
    return `${this._protocol}//nt-platform-system.azurewebsites.net/api/HttpTrigger1?code=EgkT/BXwLyAjeUu35mIGbGayYTVujRvESL5BVuXWJS8Mk90Pd4S7Rw==`;
  }

  private configSubject = new BehaviorSubject<any>(_defaultConfig);
  config = this.configSubject.asObservable();
  private _lastConfigUpdate: Date;
  private _configUpdateTimeoutSeconds = 60;

  get api_endpoint(): string {
    // return 'http://localhost:53601/api/v1';
    if (this.configSubject.value && this.configSubject.value.api_endpoint) {
      return this.configSubject.value.api_endpoint;
    }

    return _defaultConfig.api_endpoint;
  }

  get display_demo(): boolean {
    if (this.configSubject.value) {
      return this.configSubject.value.display_demo;
    }

    return _defaultConfig.display_demo;
  }

  get signal_r_endpoint(): string {
    if (
      this.configSubject.value &&
      this.configSubject.value.signal_r_endpoint
    ) {
      return this.configSubject.value.signal_r_endpoint;
    }

    return _defaultConfig.signal_r_endpoint;
  }

  get pos_api_endpoint(): string {
    // return 'https://localhost:7024/api';
    if (this.configSubject.value && this.configSubject.value.pos_api_endpoint) {
      return this.configSubject.value.pos_api_endpoint;
    }

    return _defaultConfig.pos_api_endpoint;
  }

  get fs_tax_doc(): string {
    if (this.configSubject.value && this.configSubject.value.fs_tax_doc) {
      return this.configSubject.value.fs_tax_doc;
    }

    return _defaultConfig.fs_tax_doc;
  }

  get fs_tax_pck(): string {
    if (this.configSubject.value && this.configSubject.value.fs_tax_pck) {
      return this.configSubject.value.fs_tax_pck;
    }

    return _defaultConfig.fs_tax_pck;
  }

  get fs_tax_cmd(): string {
    if (this.configSubject.value && this.configSubject.value.fs_tax_cmd) {
      return this.configSubject.value.fs_tax_cmd;
    }

    return _defaultConfig.fs_tax_cmd;
  }

  // get logger_api(): string {
  //   if (this.configSubject.value && this.configSubject.value.logger_api) {
  //     return this.configSubject.value.logger_api;
  //   }

  //   return _defaultConfig.logger_api;
  // }

  get order_queue(): string {
    if (this.configSubject.value && this.configSubject.value.order_queue) {
      return this.configSubject.value.order_queue;
    }

    return _defaultConfig.order_queue;
  }

  get store_api(): string {
    if (this.configSubject.value && this.configSubject.value.store_api) {
      return this.configSubject.value.store_api;
    }

    return _defaultConfig.store_api;
  }

  private _http: HttpClient;

  private _defaultStoreSettings = {
    enabled: false,
    hasloyalty: false,
    hasmenu: false,
    id: '',
    imagebase64: '',
    imageurl: '',
    name: '',
    storesite: '',
    storeurl: '',
    unauthorizedorders: false,
  };

  private _storeSettings: {
    enabled: boolean;
    hasloyalty: boolean;
    hasmenu: boolean;
    id: string;
    imagebase64: string;
    imageurl: string;
    name: string;
    storesite: string;
    storeurl: string;
    unauthorizedorders: boolean;
  };
  saveStoreSettings(_storeSettings: {
    enabled: boolean;
    hasloyalty: boolean;
    hasmenu: boolean;
    id: string;
    imagebase64: string;
    imageurl: string;
    name: string;
    storesite: string;
    storeurl: string;
    unauthorizedorders: boolean;
  }) {
    this._storeSettings = _storeSettings;
  }

  getStoreSettings() {
    return this._storeSettings || this._defaultStoreSettings;
  }

  constructor(
    private _httpBackend: HttpBackend,
    private _device: Device,
    @Inject('ENVIRONMENT') private environment: any,
    private appVersion: AppVersion,
    private _platform: Platform
  ) {
    this._http = new HttpClient(this._httpBackend);

    this._platform.ready().then((platform) => {
      if (platform != 'ios') {
        this._protocol = 'https:';
      }
    });
  }

  getValue(key: string) {
    if (
      this.configSubject.value &&
      this.configSubject.value[key] !== undefined
    ) {
      return this.configSubject.value[key];
    }

    return _defaultConfig[key];
  }

  getSystemConfig(forse = true): Observable<void> {
    if (forse) {
      return this._getSystemConfig().pipe(
        map((_config) => {
          if (!_config) {
            this.configSubject.next(_defaultConfig);
          } else {
            this.configSubject.next(_config);
          }
        }),
        catchError(() => of(null))
      );
    } else {
      return of(null);
    }
  }

  private _getSystemConfig(): Observable<any> {
    const env = this.environment.production ? 'production' : 'debug';
    return from(this._platform.ready()).pipe(
      mergeMap((platf) => {
        return this._http.get<any>(
          `${this._configApiUrl}&imei=${this._device.uuid}&serial=${this._device.serial}&env=${env}&platform=${platf}&schema=${this._protocol}`
        );
      })
    );
  }

  async registerDevice(companyId?: string) {
    const model: any = {};
    model.companyId = companyId;
    model.model = this._device.model;
    model.platform = this._device.platform;
    model.uuid = this._device.uuid;
    model.version = this._device.version;
    model.manufacturer = this._device.manufacturer;
    model.isVirtual = this._device.isVirtual;
    model.serial = this._device.serial;
    model.appName = 'nt.pos.terminal';

    if (this._device.platform == 'web_browser') {
      model.appVersion = this.environment.version;
    } else {
      model.appVersion = await this.appVersion.getVersionNumber();
    }

    this._registerDevice(model).subscribe();
  }

  private _registerDevice(model: any) {
    return this._http.post(
      this.api_endpoint + '/app-client/company/register-device',
      model
    );
  }

  private _requestSystemConfig(): Observable<any> {
    const env = this.environment.production ? 'production' : 'debug';
    return from(this._platform.ready()).pipe(
      mergeMap((platf) => {
        return this._http.get<any>(
          `${this._configApiUrl}&imei=${this._device.uuid}&serial=${this._device.serial}&env=${env}&platform=${platf}`
        );
      })
    );
  }

  private _getSystemConfigByTimeout(): Observable<any> {
    const _now = new Date();
    const lastUpdateBeforeSeconds = this._lastConfigUpdate
      ? Math.floor((_now.getTime() - this._lastConfigUpdate.getTime()) / 1000)
      : this._configUpdateTimeoutSeconds + 1;

    const isConfigExpired =
      lastUpdateBeforeSeconds > this._configUpdateTimeoutSeconds;

    if (this.configSubject.value && !isConfigExpired) {
      return of(this.configSubject.value);
    }

    return this._requestSystemConfig().pipe(
      mergeMap((_config) => {
        if (!_config) {
          this.configSubject.next(_defaultConfig);
          return of(_defaultConfig);
        } else {
          this.configSubject.next(_config);
          this._lastConfigUpdate = new Date();
          return of(_config);
        }
      }),
      catchError(() => of(_defaultConfig))
    );
  }

  async _getValue<T>(key: APP_CONFIG): Promise<T> {
    const _config = await this._getSystemConfigByTimeout().toPromise();
    return _config[key] || _defaultConfig[key];
  }
}
