import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import domtoimage from 'dom-to-image';

import { SocketService } from 'src/app/services/socket.service';
import { PrinterPreviewComponent } from './components/printer-preview.component';
import { differenceInSeconds, format } from 'date-fns';
import * as QRCode from 'qrcode';
import { uk } from 'date-fns/locale';

import {
  IPrinterItem,
  PrinterRepository,
} from 'src/app/private/terminal/repositories/printers.repository';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { UsbService } from 'src/app/services/usb.service';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { BluetoothLeService } from 'src/app/services/bluetooth-le.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { EscPosEncoderNew } from './esc-pos-encoder';
import { ReceiptFormatterService } from 'src/app/private/terminal/receipt-formatter.service';
import { PrintLine } from 'src/app/models/print-line.model';
import { CheckComponent } from './components/check.component';

export interface IPrintObject {
  printer: IPrinterItem;
  printText: string;
  imglogo?: string;
  isClientReceipt?: boolean;
  bottomLogo?: string;
  bottomQRCodeData?: string;
  bottomText?: string;
  extraData?: { type: 'text' | 'qrCode' | 'image' | 'space'; value?: string }[];
}

class PrintersConfig {
  terminalId: string;
  command: IPrinterCommand;
  workAreas: string;
  printers: any[];
  remoteDebug: boolean;
  printerId: string;
}

export interface IPrinterCommand {
  id: string;
  printerId: string;
  command: 'print' | 'usbSearch' | 'printTest';
  data: any;
  status: 'finished' | 'panding';
}

class PrintObject implements IPrintObject {
  printer: IPrinterItem;
  printText: string;
  imglogo?: string;
  isClientReceipt?: boolean;

  constructor(
    printer: IPrinterItem,
    text: string,
    isClientReceipt: boolean = false
  ) {
    this.printer = printer;
    this.printText = text;
    this.isClientReceipt = isClientReceipt;
  }
}

class IpGenerator {
  private _ports: number[];
  private _subnetParts = [];
  private _ipsRange: { ipAddress: string; port: number }[] = [];

  private readonly _localIpPrefix = '192.168';
  private _totalRange = 0;

  constructor(ports: number[] = [9100, 6001], subnetParts: number = 2) {
    for (let i = 0; i <= subnetParts; i++) {
      this._subnetParts.push(i);
    }

    this._ports = ports;
    this._generateIps();
    this._totalRange = this._ipsRange.length;
  }

  private _generateIps() {
    this._ipsRange = [];
    for (let subnet of this._subnetParts) {
      for (let lastPart = 1; lastPart <= 255; lastPart++) {
        for (let port of this._ports) {
          this._ipsRange.push({
            ipAddress: `${this._localIpPrefix}.${subnet}.${lastPart}`,
            port: port,
          });
        }
      }
    }
  }

  getNextIp(): { ip: { ipAddress: string; port: number }; process: number } {
    if (!this._ipsRange.length) {
      return null;
    }
    const ip = this._ipsRange.shift();
    const process = (1 - this._ipsRange.length / this._totalRange) * 100;

    return { ip, process: process };
  }
}

declare const window;
declare const chrome;
const PRINTER_CLASS = 1664;

@Injectable({ providedIn: 'root' })
export class PrinterService {
  private _printerEncoder: EscPosEncoderNew;
  private _printers: IPrinterItem[] = [];
  public showPrintPreview = false;
  onPrinterAdded = new EventEmitter<IPrinterItem[]>();
  onPrintEnd = new EventEmitter();

  isScanInProcess: number;
  scannedIp: string;
  get printers(): IPrinterItem[] {
    return this._printers;
  }
  private _scanIpInProcess = false;
  private _ipGenerator: IpGenerator;
  private _scanNetworkTimer = timer(500, 500);
  private _scanNetworkSub: Subscription;
  get hasPrinters() {
    return this.printers && this.printers.length;
  }

  get isPrinterConfigured() {
    return this.printers.find((p) => p.printCheck) != undefined;
  }

  get isDesktop() {
    return chrome.webview?.hostObjects?.platform == 'desktop';
  }
  onDesktopPrinters = new EventEmitter<any[]>();
  private _printQueue: {
    printer: IPrinterItem;
    lines: any[];
    errors?: any[];
  }[] = [];

  constructor(
    private _socketService: SocketService,
    private _printerRepo: PrinterRepository,
    private _btService: BluetoothService,
    private _usbService: UsbService,
    private modalCtrl: ModalController,
    private shared: SharedService,
    private bluetoothLeService: BluetoothLeService,
    private monitor: MonitoringService,
    private _api: ApiV1Service
  ) {
    this._initData();
  }

  private async _initData() {
    this._printerEncoder = new EscPosEncoderNew();
    this._printerRepo.getPrinters().then((res: IPrinterItem[]) => {
      if (res && res.length > 0) {
        this._printers = res;
      }
    });

    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.addEventListener('message', (event: any) => {
        const res = event.data;
        if (res.DataType == 'Printers') {
          this.onDesktopPrinters.emit(res.Message);
        }
      });
    }
  }

  async refreshPrinters() {
    const printers = await this._getSyncedPrinters().catch((err) => {
      this.shared.showErrorToast('Помилка завантаження даних');
      throw err;
    });

    if (!printers.length) {
      this.shared.showErrorToast('Збережені принтери відсутні');
      return;
    }

    await this._printerRepo.savePrinters(printers);
    this.onPrinterAdded.emit(printers);
    this.shared.showSuccessToast('Принтери оновлено');
  }

  syncPrinters() {
    this._printerRepo.getPrinters().then((savedPrinters) => {
      if (savedPrinters && savedPrinters.length) {
        this._api.updatePrinters(savedPrinters).subscribe();
      } else {
        this._getSyncedPrinters().then((res) => {
          if (res && res.length) {
            this._printerRepo.savePrinters(res);
          }
        });
      }
    });
  }

  private _getSyncedPrinters() {
    return this._api
      .getPrinters()
      .pipe(
        map((res) => {
          if (res && res.length) {
            for (let p of res) {
              if (p.workAreasJson && p.workAreasJson != 'null') {
                p.work_areas = JSON.parse(p.workAreasJson);
              }
            }
          }

          return res;
        })
      )
      .toPromise();
  }

  getPrintObject(
    printer: IPrinterItem,
    text: string,
    isClientReceipt: boolean = false
  ): IPrintObject {
    return new PrintObject(printer, text, isClientReceipt);
  }

  async printScope(printObjects: IPrintObject[]) {
    if (this.showPrintPreview) {
      this.openPrintPreview(printObjects);
    } else {
      const printers: { [key: string]: IPrintObject[] } = {};
      printObjects.forEach((pObj) => {
        const key = `${pObj.printer.address}`;
        if (!printers[key]) {
          printers[key] = [];
        }

        printers[key].push(pObj);
      });

      const addresses = Object.keys(printers);
      for await (const printer of addresses) {
        await this._sendToPrinter(printers[printer]);
      }

      this.onPrintEnd.emit();
    }
  }

  displayLines(lines: PrintLine[], orderUuid?: string): void {
    this._linesToHtml(lines).then((html) => {
      this.modalCtrl
        .create({
          component: CheckComponent,
          componentProps: {
            checkUuid: orderUuid,
            htmlString: html,
          },
          canDismiss: true,
          backdropDismiss: true,
          cssClass: 'check-preview',
        })
        .then((modal) => modal.present());
    });
  }

  printLines(
    lines: PrintLine[],
    areaId?: number,
    orderUuid?: string,
    isSale = false
  ): void {
    let printers: IPrinterItem[];
    if (areaId > 0) {
      printers = this._getAreaPrinters(areaId, isSale);
      if (!printers.length) {
        return;
      }
    } else {
      printers = this._getSelectedPrinters();
    }

    if (!printers.length) {
      this.displayLines(lines, orderUuid);
      return;
    }

    for (const printer of printers) {
      this._addToPrintQueue(printer, lines);
    }

    this._processQueue();
  }

  private async _printItem(printer: IPrinterItem, lines: any[]) {
    const printerType = printer.type;

    if (printerType == 'bt') {
      const encoder = await this._encodeLines(lines, printer);
      return this._btService.sendDataToDevice(
        printer.address,
        encoder.encode().buffer
      );
    }

    if (printerType == 'btle') {
      const encoder = await this._encodeLines(lines, printer);
      return this.bluetoothLeService.sendDataToDevice(
        printer,
        encoder.encode()
      );
    }

    if (printerType == 'net') {
      const encoder = await this._encodeLines(lines, printer);
      return this._socketService.sendData(
        printer.ip,
        printer.port,
        encoder.encode().buffer
      );
    }

    if (printerType == 'usb') {
      const encoder = await this._encodeLines(lines, printer);
      return this._usbService.send(printer.name, encoder.encode().buffer);
    }

    if (printerType == 'web') {
      const html = await this._linesToHtml(lines);
      return new Promise((resolve) => {
        var frame1: any = document.createElement('iframe');
        frame1.name = 'frame1';
        frame1.style.position = 'absolute';
        frame1.style.top = '-1000000px';
        document.body.appendChild(frame1);
        var frameDoc = frame1.contentWindow
          ? frame1.contentWindow
          : frame1.contentDocument.document
          ? frame1.contentDocument.document
          : frame1.contentDocument;

        frameDoc.document.open();
        frameDoc.document.write(
          '<html><head><title>Print receipt</title><style>table td{padding;0} html,body{margin:0;padding;0;font-size:14px;font-family:sans-serif,Helvetica Neue,Lucida Grande,Arial}</style>'
        );
        frameDoc.document.write('</head><body>');
        frameDoc.document.write(html);

        const bottomRows = +printer.bottomRows;
        if (!isNaN(bottomRows)) {
          for (let i = 0; i < bottomRows; i++) {
            frameDoc.document.write(`<div>&nbsp;</div><div>&nbsp;</div>`);
          }
        }
        frameDoc.document.write('</body></html>');
        frameDoc.document.close();

        setTimeout(function () {
          window.frames['frame1'].focus();
          window.frames['frame1'].print();
          document.body.removeChild(frame1);

          resolve(null);
        }, 500);
      });
    }
  }

  private _isPrintInProcess = false;
  private _processQueueStartedAt: Date | null;
  private async _processQueue() {
    if (this._isPrintInProcess && this._processQueueStartedAt) {
      const processDuration = differenceInSeconds(
        new Date(),
        this._processQueueStartedAt
      );
      if (processDuration < 20) {
        return;
      }
    }

    const items = this._printQueue;
    if (!items.length) {
      return;
    }

    this._processQueueStartedAt = new Date();
    this._isPrintInProcess = true;
    this.shared.notify('Друк...', 60);

    const notPrintedItems = [];
    for await (let item of items) {
      await this._printItem(item.printer, item.lines).catch((err) => {
        if (!item.errors) {
          item.errors = [];
        }

        item.errors.push(err);
        notPrintedItems.push(item);

        this.monitor.logException(err, 1, {
          service: 'PrinterService _printItem',
          point: `${item.printer.type}`,
        });
        this.shared.showErrorToast(err);
      });
    }

    this._isPrintInProcess = false;
    this._printQueue = notPrintedItems;
    this.shared.closeNotify();
    this.onPrintEnd.emit();
  }

  printLinesMultiple(
    items: { workAreaId: number; printLines: PrintLine[] }[],
    isSales = false
  ) {
    for (let item of items) {
      this.printLines(item.printLines, item.workAreaId, null, isSales);
    }

    this._processQueue();
  }

  private _addToPrintQueue(printer: IPrinterItem, lines: any[]) {
    this._printQueue.push({ printer, lines });
  }

  private async _linesToHtml(lines: PrintLine[]) {
    const _formatter = new ReceiptFormatterService();
    _formatter.config.currency = '';
    _formatter.config.ruler = '-';

    for (var line of lines) {
      if (line.type == 'qrcode') {
        const opts = {
          errorCorrectionLevel: 'L',
          type: 'image/jpeg',
          quality: 0.3,
          margin: 1,
          version: 6,
          width: '120',
        };

        const _qrCodeDataUrl: string = await QRCode.toDataURL(line.value, opts);
        line.type = 'image';
        line.value = _qrCodeDataUrl;
      }
    }

    return _formatter.createHtml(lines);
  }

  getCharectersLength(printer: IPrinterItem) {
    if (!printer) {
      return 32;
    }

    if (printer.printWidthSymbols > 0) {
      return printer.printWidthSymbols;
    }

    if (printer.printWidth == '80' && printer.textSize == 'small') {
      return 52;
    }

    if (printer.printWidth == '80' && printer.textSize == 'normal') {
      return 42;
    }

    if (printer.printWidth == '58' && printer.textSize == 'small') {
      return 42;
    }

    if (printer.printWidth == '58') {
      return 32;
    }

    return +printer.printWidth;
  }
  private async _encodeLines(
    lines: PrintLine[],
    printer: IPrinterItem
  ): Promise<EscPosEncoderNew> {
    const _formatter = new ReceiptFormatterService();
    _formatter.config.currency = '';
    _formatter.config.ruler = '-';
    _formatter.config.width = this.getCharectersLength(printer);

    const encoder = new EscPosEncoderNew();
    encoder.raw([0x1c, 0x2e]); // turn of Hanzi character mode

    for (let line of lines) {
      if (
        line.type == 'text' ||
        line.type == 'ruler' ||
        line.type == 'properties' ||
        line.type == 'empty'
      ) {
        let text = _formatter.createLine(line);
        text = this._replaceCyrillicChars(text);
        if (printer.codePageStrange) {
          encoder
            .codepage(printer.codePageStrange, printer.codePageNumber)
            .text('', 0)
            .codepage(printer.codePage, printer.codePageNumber)
            .size(printer.textSize)
            .text(text, 0)
            .newline();
        } else {
          encoder
            .codepage(printer.codePage, printer.codePageNumber)
            .size(printer.textSize)
            .text(text, 0)
            .newline();
        }
      }

      if (line.type == 'image' && line.value) {
        const _width = printer.logoWidth
          ? printer.logoWidth - (printer.logoWidth % 8)
          : 200;
        const _height = printer.logoHeight
          ? printer.logoHeight - (printer.logoHeight % 8)
          : 200;

        const _imageData = await this._base64ToImage(line.value);
        if (!_imageData) {
          return;
        }
        encoder
          .align('center')
          .image(
            _imageData,
            _width,
            _height,
            printer.logoAlgorithm,
            printer.logoThreshold
          );
      }

      if (line.type == 'logoImage' && line.value && printer.printLogo) {
        const _width = printer.logoWidth
          ? printer.logoWidth - (printer.logoWidth % 8)
          : 100;
        const _height = printer.logoHeight
          ? printer.logoHeight - (printer.logoHeight % 8)
          : 100;

        const _imageData = await this._base64ToImage(line.value);
        if (!_imageData) {
          return;
        }
        encoder
          .align('center')
          .image(
            _imageData,
            _width,
            _height,
            printer.logoAlgorithm,
            printer.logoThreshold
          );
      }

      if (line.type == 'qrcode' && line.value) {
        if (printer.qrCodeUseImg) {
          const opts = {
            errorCorrectionLevel: 'L',
            type: 'image/jpeg',
            quality: 0.3,
            margin: 1,
            version: 6,
            width: '120',
          };

          const _qrCodeDataUrl: string = await QRCode.toDataURL(
            line.value,
            opts
          );
          const qrCodeImg = await this._dataUrlToImage(_qrCodeDataUrl);
          encoder
            .newline()
            .align('center')
            .image(qrCodeImg, 200, 200, 'atkinson', 128);
        } else {
          encoder
            .newline()
            .align('center')
            .qrcode(
              line.value,
              +printer.qrCodeModel,
              +printer.qrCodeSize,
              printer.qrCodeErrorLevel
            );
        }
      }
    }

    const bottomRows = +printer.bottomRows;
    if (!isNaN(bottomRows)) {
      for (let i = 0; i < bottomRows; i++) {
        encoder.newline();
      }
    }

    encoder.cut('partial');
    return encoder;
  }

  private _getSelectedPrinters() {
    return this.printers.filter((p) => p.printCheck);
  }

  private _getAreaPrinters(areaId: number, isSale: boolean): IPrinterItem[] {
    return this.printers.filter((p) => {
      if (!p.work_areas || !p.work_areas.length) {
        return false;
      }

      const printerWorkArea = p.work_areas.find((wa) => wa.id == areaId);
      if (!printerWorkArea) {
        return false;
      }

      return !isSale || p.printWorkAreasOnPayment;
    });
  }

  private _sendToPrinter(prints: IPrintObject[]): Promise<any> {
    const printerType = prints[0].printer.type;
    prints.forEach((p) => {
      p.printText = this._replaceCyrillicChars(p.printText);
    });

    if (printerType == 'bt') {
      return this._sendToBlueToothPrinter(prints);
    } else if (printerType == 'btle') {
      return this._sendToBtlePrinter(prints);
    } else if (printerType == 'net') {
      return this._sendToLanPrinter(prints);
    } else if (printerType == 'usb') {
      return this._sendToUsbPrinter(prints);
    } else if (printerType == 'web') {
      return this._sendToWebPrinter(prints);
    }
  }

  async scanPrinters(fullScan: boolean = false) {
    // _addTestPrinter();

    if (this.isDesktop) {
      window.chrome.webview.postMessage('getPrinters');

      alert('isDesktop - true');

      return;
    }

    if (this.isScanInProcess > 0 && this.isScanInProcess < 100) {
      return;
    }

    this._startScanNetwork(fullScan);
    this._bluetoothScan();
    this._bluetoothLeScan();
    this._usbScan();
  }

  private async _bluetoothScan() {
    console.log('_bluetoothScan');
    try {
      const btPrinters = await this._btService
        .getConnectedDevices()

        .catch((err) => {
          console.log('getConnectedDevices err');
          this.shared.showErrorToast(err);
          return [];
        });

      btPrinters.forEach((bp) => {
        if (
          bp.name &&
          (bp.name.toLowerCase().includes('printer') ||
            bp.name.toLowerCase().includes('mpt') ||
            bp.name.toLowerCase() == 'PT2200B'.toLowerCase() ||
            bp.class == PRINTER_CLASS)
        ) {
          const printer = this._printerRepo.createPrinter(
            bp.name,
            bp.address,
            null,
            'bt',
            'cp866'
          );

          this._addPrinter(printer);
        }
      });
    } catch (err) {
      console.log('_bluetoothScan error');
      console.log(JSON.stringify(err));
    }
  }

  private async _usbScan() {
    console.log('_usbScan');
    try {
      const usbPrinters = await this._usbService
        .getUsbPrinters()
        .catch(() => []);

      usbPrinters.forEach((p) => {
        const printer = this._printerRepo.createPrinter(
          'USB Printer',
          null,
          null,
          'usb',
          'cp866'
        );

        printer.name = p.name;
        this._addPrinter(printer);
      });
    } catch (err) {
      console.log('_usbScan error');
      console.log(JSON.stringify(err));
    }
  }

  private async _bluetoothLeScan() {
    console.log('_bluetoothLeScan');
    try {
      this.bluetoothLeService.startScan().subscribe(
        (res) => {
          console.log('bluetoothLeService res');

          if (res) {
            if (
              res.name &&
              (res.name.toLowerCase().includes('printer') ||
                res.name.toLowerCase().includes('mpt') ||
                res.name.toLowerCase() == 'PT2200B'.toLowerCase() ||
                res.class == PRINTER_CLASS)
            ) {
              const printer = this._printerRepo.createPrinter(
                res.name,
                res.address,
                null,
                'btle',
                'cp866'
              );

              if (res.name.toLowerCase() == 'PT2200B'.toLowerCase()) {
                printer.codePageNumber = 6;
                printer.codePage = 'windows1251';
              }

              this._addPrinter(printer);
            }
          }
        },
        (err) => {
          console.log('bluetoothLeService err');
        }
      );

      setTimeout(() => this.bluetoothLeService.stopScan(), 10000);
    } catch (err) {
      console.log('_bluetoothLeScan error');
      console.log(JSON.stringify(err));
    }
  }

  async createWebPrinter() {
    const printers = await this._printerRepo.getPrinters();
    const printerweb = printers.find((p) => p.type === 'web');
    if (printerweb) {
      return;
    } else {
      const webprinter = this._printerRepo.createPrinter(
        'WEB Printer',
        '0.0.0.0',
        null,
        'web'
      );

      this._addPrinter(webprinter);
    }
  }

  private _startScanNetwork(fullScan = false) {
    let subnetParts = 2;
    if (fullScan) {
      subnetParts = 255;
    }

    this._ipGenerator = new IpGenerator([9100, 6001], subnetParts);
    this._scanNetworkSub = this._scanNetworkTimer.subscribe(() =>
      this._scanNetwork()
    );
  }

  stopScanNetwork() {
    if (this._scanNetworkSub) {
      this._scanNetworkSub.unsubscribe();
    }
    this.isScanInProcess = 0;
  }

  findPrinterOnIp(ip: string, port: number): Observable<boolean> {
    return from(this._checkConnection(ip, port)).pipe(
      map((res) => {
        if (res) {
          this._addLanPrinter(ip, port);
          return true;
        }

        return false;
      })
    );
  }

  private _scanNetwork() {
    if (!this._ipGenerator) {
      return;
    }

    if (this._scanIpInProcess) {
      return;
    }

    this._scanIpInProcess = true;

    const nextIp = this._ipGenerator.getNextIp();
    if (nextIp == null) {
      this.stopScanNetwork();
    }

    this.isScanInProcess = nextIp.process;
    const ipAddress = nextIp.ip.ipAddress;
    const port = nextIp.ip.port;
    this.scannedIp = ipAddress;

    this._checkConnection(ipAddress, port)
      .then((res) => {
        if (res) {
          this._addLanPrinter(ipAddress, port);
          if (this.showPrintPreview) {
            throw 'ЗНАЙДЕНО ПРОСТРІЙ. IP: ' + ipAddress;
          }
        } else {
          if (this.showPrintPreview) {
            throw 'Помилка підключення. IP: ' + ipAddress;
          }
        }
        this._scanIpInProcess = false;
      })
      .catch((err) => {
        console.log('_checkConnection err');

        this._scanIpInProcess = false;
      });
  }

  private _checkConnection(ip: string, port: number): Promise<boolean> {
    return this._socketService.checkConnection(ip, port).catch(() => {
      return false;
    });
  }

  private _addLanPrinter(ip: string, port: number) {
    const printer = this._printerRepo.createPrinter(
      'LAN Printer',
      ip,
      port,
      'net',
      'windows1251',
      'cp936'
    );
    printer.address = ip;

    this._addPrinter(printer);
  }

  // selectPrinter(p: IPrinterItem) {
  //   const printer = this._findPrinter(p);
  //   if (!printer) {
  //     return;
  //   }

  //   this._printers.forEach((p) => (p.selected = false));
  //   printer.selected = true;

  //   this._printerRepo
  //     .savePrinters(this._printers)
  //     .subscribe(() => (this._printers = this._printers));
  // }

  showPreview(showPrintPreview: boolean) {
    this.showPrintPreview = showPrintPreview;
  }

  async getPrinterData(terminalId?: string): Promise<PrintersConfig> {
    const printers = await this._getDevices();
    const terminal = new PrintersConfig();
    terminal.terminalId = terminalId;
    terminal.printers = printers;
    return terminal;
  }

  private async _getDevices() {
    const printers = await this._printerRepo.getPrinters();
    if (printers && printers.length > 0) {
      return printers;
    } else {
      return [];
    }
  }

  async printTest(printer: IPrinterItem) {
    const date = format(new Date(), 'dd MMM yyyy HH:mm', {
      locale: uk,
    });

    const lines: PrintLine[] = [];
    lines.push({
      type: 'text',
      value: `\n\nTurboPOS ${date}\nТестовий друк\n\nЄІЇєії`,
    });

    lines.push({
      type: 'ruler',
    });

    lines.push({
      type: 'properties',
      lines: [
        { name: 'Місто', value: 'Київ' },
        { name: 'Вулиця', value: 'Шевченка' },
        { name: 'Будинок', value: '25' },
      ],
    });
    lines.push({
      type: 'ruler',
    });

    lines.push({
      type: 'qrcode',
      value: 'https://turbopos.net',
    });
    this._addToPrintQueue(printer, lines);
    this._processQueue();
  }

  async testQrCodePrint(printer: IPrinterItem) {
    const printObject = this.getPrintObject(printer, '');
    printObject.bottomQRCodeData = 'https://turbopos.net';
    return this._sendToPrinter([printObject]);
  }

  async testLogoPrint(printer: IPrinterItem) {
    const printObject = this.getPrintObject(printer, '');
    printObject.bottomQRCodeData = 'https://turbopos.net';
    return this._sendToPrinter([printObject]);
  }

  private async _dataUrlToImage(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.src = dataUrl;
    });
  }

  textToPng(
    text: string,
    htmlWidth = '60mm',
    imageWidth = 240,
    fontSize = 20
  ): Promise<HTMLImageElement> {
    const container = document.createElement('div');
    container.style.width = htmlWidth ? htmlWidth : '60mm';
    container.style.color = 'black';

    const textElement = document.createElement('pre');
    textElement.style.backgroundColor = 'white';
    textElement.style.alignItems = 'center';
    textElement.style.fontWeight = 'bold';
    textElement.style.fontSize = fontSize + 'px';
    textElement.style.whiteSpace = 'pre-wrap';
    textElement.innerHTML = text;

    container.appendChild(textElement);
    document.body.appendChild(container);

    // 440 for web print
    return new Promise((resolve) => {
      domtoimage
        .toPng(container, { width: imageWidth, bgcolor: 'white' })
        .then((dataUrl) => {
          let img = new Image();
          img.onload = () => {
            document.body.removeChild(container);
            resolve(img);
          };
          img.src = dataUrl;
        })
        .catch((err) => {
          resolve(null);
        });
    });
  }

  printText3(printerId: string, text: string): Promise<any> {
    return this._getDevices().then((res) => {
      const printer = res.find(
        (p) => printerId && p.uuid.toLowerCase() == printerId.toLowerCase()
      );

      if (!printer) {
        throw new Error('Printer not found.');
      }

      if (!text) {
        throw new Error('Text not provided.');
      }

      const printObj: IPrintObject = new PrintObject(printer, text);
      return this._sendToPrinter([printObj]);
    });
  }

  printText2(text: string, printerId?: string) {
    this._getDevices().then((res) => {
      const defaultPrinter = res.find(
        (p) =>
          (printerId && p.uuid.toLowerCase() == printerId.toLowerCase()) ||
          p.printCheck
      );
      if (defaultPrinter) {
        const printObj: IPrintObject = new PrintObject(defaultPrinter, text);

        if (this.showPrintPreview) {
          this.openPrintPreview([printObj]);
        } else {
          this._sendToPrinter([printObj]);
        }
      }
    });
  }

  private async _getImagePrintData(
    image: string,
    width: number,
    height: number,
    algorithm: string,
    threshold: number
  ): Promise<Uint8Array> {
    const _imageData = await this._base64ToImage(image);
    if (!_imageData) {
      return;
    }

    const _width = width ? width - (width % 8) : 200;
    const _height = height ? height - (height % 8) : 200;

    this._printerEncoder
      .align('center')
      .image(_imageData, _width, _height, algorithm, threshold);
  }

  private async _getQrCodePrintData(
    data: string,
    useImg: boolean,
    model: number = 1,
    size: number = 6,
    errorLevel: string = 'm'
  ): Promise<void> {
    if (useImg) {
      const opts = {
        errorCorrectionLevel: 'L',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 1,
        version: 6,
        width: '120',
      };

      const _qrCodeDataUrl: string = await QRCode.toDataURL(data, opts);
      const _qrCodeDataString: string = await QRCode.toString(data, opts);
      const qrCodeImg = await this._dataUrlToImage(_qrCodeDataUrl);
      this._printerEncoder
        .newline()
        .align('center')
        .image(qrCodeImg, 200, 200, 'atkinson', 128);
    } else {
      this._printerEncoder
        .newline()
        .align('center')
        .qrcode(data, model, size, errorLevel);
    }
  }

  private async _getGraphicPrintData(
    printText: string,
    graphicPrintHtmlWidth: string,
    graphicPrintImageWidth: number,
    graphicPrinFontSize: number
  ): Promise<void> {
    const textImage = await this.textToPng(
      printText,
      graphicPrintHtmlWidth,
      graphicPrintImageWidth,
      graphicPrinFontSize
    );
    let height = textImage.height; // * 1.8;
    height = height - (height % 8);

    let width = textImage.width; // * 1.8;
    width = width - (width % 8);

    this._printerEncoder.image(textImage, width, height, 'atkinson', 128);
  }

  private async _sendToBlueToothPrinter(data: IPrintObject[]): Promise<any> {
    this._printerEncoder.initialize();
    const printer = data[0].printer;
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );
      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else if (p.printText) {
        this.encodeText(p.printText, printer.textSize, p.printer.codePage);
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(p.bottomText, printer.textSize, p.printer.codePage);
      }

      if (p.extraData && p.extraData.length) {
        for (let ed of p.extraData) {
          if (ed.type == 'text') {
            const text = this._replaceCyrillicChars(ed.value);
            this.encodeText(text, printer.textSize, p.printer.codePage);
          }

          if (ed.type == 'qrCode') {
            await this._getQrCodePrintData(
              ed.value,
              printer.qrCodeUseImg,
              +printer.qrCodeModel,
              +printer.qrCodeSize,
              printer.qrCodeErrorLevel
            );
          }

          if (ed.type == 'space') {
            this._bottomLines(1);
          }
        }
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    this.shared.notify('Друк...', 60);
    return this._btService
      .sendDataToDevice(printer.address, this._printerEncoder.encode().buffer)
      .catch((err) => this.shared.showErrorToast(err))
      .finally(() => this.shared.closeNotify());
  }

  private async _sendToBtlePrinter(data: IPrintObject[]) {
    this._printerEncoder.initialize();
    const printer = data[0].printer;
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );
      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else if (p.printText) {
        this.encodeText(
          p.printText,
          printer.textSize,
          p.printer.codePage,
          null,
          p.printer.codePageNumber
        );
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(
          p.bottomText,
          printer.textSize,
          p.printer.codePage,
          null,
          p.printer.codePageNumber
        );
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    this.shared.notify('Друк...', 10);
    return this.bluetoothLeService
      .sendDataToDevice(printer, this._printerEncoder.encode())
      .catch((err) => this.shared.showErrorToast(err))
      .finally(() => this.shared.closeNotify());
  }

  private async _sendToLanPrinter(data: IPrintObject[]): Promise<any> {
    const printer = data[0].printer;
    this._printerEncoder.initialize();
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );

      if (p.printer.graphicPrint) {
        this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else {
        this.encodeText(
          p.printText,
          printer.textSize,
          printer.codePage,
          printer.codePageStrange
        );
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(
          p.bottomText,
          printer.textSize,
          printer.codePage,
          printer.codePageStrange
        );
      }

      if (p.extraData && p.extraData.length) {
        for (let ed of p.extraData) {
          if (ed.type == 'text') {
            const text = this._replaceCyrillicChars(ed.value);
            this.encodeText(text, printer.textSize, p.printer.codePage);
          }

          if (ed.type == 'qrCode') {
            await this._getQrCodePrintData(
              ed.value,
              printer.qrCodeUseImg,
              +printer.qrCodeModel,
              +printer.qrCodeSize,
              printer.qrCodeErrorLevel
            );
          }

          if (ed.type == 'space') {
            this._bottomLines(1);
          }
        }
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    return this._socketService.sendData(
      printer.ip,
      printer.port,
      this._printerEncoder.encode().buffer
    );
  }

  private async _sendToUsbPrinter(data: IPrintObject[]): Promise<any> {
    const printer = data[0].printer;
    this._printerEncoder.initialize();
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );

      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else {
        this.encodeText(p.printText, printer.textSize, printer.codePage);
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(p.bottomText, printer.textSize, printer.codePage);
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    return this._usbService
      .send(printer.name, this._printerEncoder.encode().buffer)
      .catch((err) => {
        this.monitor.logException(err, 1, {
          service: 'PrinterService',
          point: 'USBPrint',
        });
      });
  }

  private async _sendToWebPrinter(data: IPrintObject[]): Promise<any> {
    const print = data[0];

    const logoImage = await this._base64ToImage(print.imglogo);

    let _qrCodeImg;
    if (print.bottomQRCodeData) {
      const _qrCodedataUrl: string = await QRCode.toDataURL(
        print.bottomQRCodeData,
        {
          type: 'terminal',
          width: '100',
        }
      );

      _qrCodeImg = await this._dataUrlToImage(_qrCodedataUrl);
    }

    const width = print.printer.printWidth + 'mm';
    if (print.printer.graphicPrint) {
      const textImage = await this.textToPng(
        print.printText,
        print.printer.graphicPrintHtmlWidth,
        print.printer.graphicPrintImageWidth,
        print.printer.graphicPrinFontSize
      );

      return await this._webPrint(
        null,
        width,
        logoImage,
        _qrCodeImg,
        textImage,
        null,
        print.bottomText
      );
    } else {
      return await this._webPrint(
        print.printText,
        width,
        logoImage,
        _qrCodeImg,
        null,
        print.printer.logoWidth,
        print.bottomText
      );
    }
  }

  private _bottomLines(bottomLines: number) {
    for (let i = 0; i < bottomLines; i++) {
      this._printerEncoder.newline();
    }
  }

  private encodeText(
    text: string,
    textSize: 'small' | 'normal',
    codePage: string,
    codePageStrange?: string,
    codePageNumber?: number
  ) {
    if (codePageStrange) {
      this._printerEncoder
        .codepage(codePageStrange, codePageNumber)
        .text('', 0)
        .codepage(codePage, codePageNumber)
        .size(textSize)
        .text(text, 0)
        .newline();
    } else {
      this._printerEncoder
        .codepage(codePage, codePageNumber)
        .size(textSize)
        .text(text, 0)
        .newline();
    }
  }

  private async _base64ToImage(imageData: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      if (!imageData) {
        resolve(null);
      } else {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.src = 'data:' + imageData;
      }
    });
  }

  private _webPrint(
    text: string,
    width: string,
    logoImage?: HTMLImageElement,
    qrCode?: HTMLImageElement,
    imageData?: HTMLImageElement,
    logoWidth = 150,
    bottomText?: string
  ): Promise<any> {
    return new Promise((resolve) => {
      var frame1: any = document.createElement('iframe');
      frame1.name = 'frame1';
      frame1.style.position = 'absolute';
      frame1.style.top = '-1000000px';
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;

      frameDoc.document.open();
      frameDoc.document.write(
        '<html><head><title>Print receipt</title><style>table td{padding;0} html,body{margin:0;padding;0;font-size:14px;font-family:sans-serif,Helvetica Neue,Lucida Grande,Arial}</style>'
      );

      frameDoc.document.write('</head><body>');
      if (logoImage) {
        frameDoc.document.write(
          `<div style="text-align: center"><span><img src="${logoImage.src}" width="${logoWidth}"></span></div>`
        );
      }

      if (text) {
        frameDoc.document.write(`<div>${text}</div>`);
      }

      if (imageData) {
        frameDoc.document.write(`<img src="${imageData.src}" >`);
      }

      if (qrCode) {
        frameDoc.document.write(
          `<div style="text-align: center"><span><img src="${qrCode.src}"></span></div>`
        );
      }

      if (bottomText) {
        frameDoc.document.write(
          `<div style="text-align: center">${bottomText}</div>`
        );
      }

      frameDoc.document.write(`<div>&nbsp;</div><div>&nbsp;</div>`);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();

      setTimeout(function () {
        window.frames['frame1'].focus();
        window.frames['frame1'].print();
        document.body.removeChild(frame1);

        resolve(null);
      }, 500);
    });
  }

  getPrinter(ipAddress?: string, uuid?: string): Observable<IPrinterItem> {
    return from(this._getDevices()).pipe(
      map((res) => {
        return res.find(
          (printer) =>
            (ipAddress && printer.ip == ipAddress) ||
            (uuid && printer.uuid == uuid)
        );
      })
    );
  }

  async updatePrinter(printer: IPrinterItem) {
    if (printer.work_areas && printer.work_areas.length) {
      printer.workAreasJson = JSON.stringify(printer.work_areas);
    }

    await this._printerRepo.updatePrinter(printer);
    this._api.updatePrinters([printer]).subscribe();
    this._getDevices().then((res) => (this._printers = res));
  }

  private async _addPrinter(newPrinter: IPrinterItem) {
    const printers = await this._getDevices();
    const printer = printers.find(
      (p) =>
        (p.ip === newPrinter.ip && p.port === newPrinter.port) ||
        p.uuid === newPrinter.uuid ||
        p.address === newPrinter.address
    );

    if (!printer) {
      if (!newPrinter.name) {
        newPrinter.name = 'Printer';
      }

      newPrinter.printWidth = '58';
      newPrinter.printCheck = true;

      printers.push(newPrinter);
      await this._printerRepo.savePrinter(newPrinter);
      this._printers = printers;
      this.onPrinterAdded.emit(printers);
      this._api.updatePrinters([newPrinter]).subscribe();
    }
  }

  async removePrinter(p: IPrinterItem): Promise<void> {
    await this._printerRepo.deletePrinter(p);
    this._api.deletePrinter(p.uuid).subscribe();
    this._printers = await this._getDevices();
  }

  async openPrintPreview(printObjects: any[]): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: PrinterPreviewComponent,
      cssClass: 'print-preview-modal',
      backdropDismiss: false,
      componentProps: {
        printObjects: printObjects,
        printTestAction: (printer: IPrinterItem) => this.printTest(printer),
        printAction: (res) => this._sendToPrinter([res]),
      },
    });
    modal.present();
    return modal.onWillDismiss();
  }

  async open(): Promise<any> {
    const importedModuleFile = await import('./printer.module');

    const modal = await this.modalCtrl.create({
      component: importedModuleFile.PrinterModule.getPrintersComponent(),
      cssClass: 'printers-modal',
      backdropDismiss: false,
      componentProps: {
        printerService: this,
      },
    });
    modal.present();
    return modal.onWillDismiss();
  }

  private _replaceCyrillicChars(text: string): string {
    let result: string[] = [];
    for (let letter of text) {
      switch (letter.charCodeAt(0)) {
        case 8211: // –
        case 8212: // —
          result.push(String.fromCharCode(45)); // -
          break;

        case 1110: // і
          result.push(String.fromCharCode(105)); // i
          break;

        case 1030: // І
          result.push(String.fromCharCode(73)); // i
          break;

        case 171: // «
        case 187: // »
          result.push(String.fromCharCode(34)); // "
          break;

        case 8217: // ’
          result.push(String.fromCharCode(39)); // "
          break;

        case 1169: // ґ
          result.push(String.fromCharCode(1075)); // г
          break;

        default:
          result.push(letter);
      }
    }

    return result.join('');
  }
}
