import { Component, Injectable, EventEmitter } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import DateUtils from '../utility/date-utils';
import { SelectModalComponent } from '../shared/select-modal/select-modal.component';
import { TouchDatepickerComponent } from '../shared/touch-datepicker.componen';
import { SendEmailDialogComponent } from '../common/send-email-dialog/send-email-dialog.component';
import { ApiV1Service } from './api-v1.service';
import { CalendarModal, CalendarModalOptions } from 'ion2-calendar';
import { UUID } from 'angular2-uuid';

export class SelectModalItem {
  label: string;
  subLabel?: string;
  note?: string;
  value: any;
}

interface ITostNotification {
  title: string;
  msg: string;
  id?: string;
  timeout?: number;
  color?: 'danger' | 'success' | 'warn';
  hideButtons?: boolean;
  sound?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SharedService {
  onToggleSidenav: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private api: ApiV1Service
  ) {}

  private _toasts: ITostNotification[] = [];
  notifyToast(toast: ITostNotification) {
    toast.id = UUID.UUID();
    this._toasts.unshift(toast);

    if (toast.timeout) {
      this._setToastCloseTimeout(toast.id, toast.timeout);
    }

    let container = document.getElementById('notifyToastContainer');
    if (!container) {
      container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.right = '10px';
      container.style.bottom = '50px';
      container.id = 'notifyToastContainer';
      document.body.appendChild(container);
      container.addEventListener('click', (el: any) =>
        this._handleToastNotifyEvent(el)
      );
    }

    const _toast = this._buildToastNotification(toast);
    container.prepend(_toast);

    if (toast.sound === undefined || toast.sound == true) {
      this._playSound();
    }
  }

  private _setToastCloseTimeout(toastId: string, timeout: number) {
    setTimeout(() => this._closeToast(toastId), timeout);
  }

  private _closeToast(toastId: string) {
    const toast = document.querySelector(
      `#notifyToastContainer [data-id='${toastId}']`
    );
    if (toast) {
      toast.parentNode.removeChild(toast);
    }
    const updatedToasts: any[] = [];
    for (let t of this._toasts) {
      if (t.id == toastId) {
        continue;
      }

      updatedToasts.unshift(t);
    }

    this._toasts = updatedToasts;
  }

  private _buildToastNotification(notif: ITostNotification): HTMLElement {
    const toast = document.createElement('div');
    toast.style.border = '1px solid yellow';
    toast.style.backgroundColor = 'rgb(0, 0, 0, 0.8)';
    toast.style.color = '#ffff';
    toast.style.minHeight = '50px';
    toast.style.minWidth = '200px';
    toast.style.maxWidth = '400px';
    toast.style.marginBottom = '10px';
    toast.style.transition = 'all 3s';
    toast.style.borderRadius = '6px';
    toast.dataset.id = notif.id;

    if (notif.title) {
      const title = document.createElement('div');
      title.style.borderBottom = '1px solid grey';
      title.style.padding = '4px 10px 0 20px';
      title.innerText = notif.title;
      toast.appendChild(title);
    }

    if (notif.msg) {
      const title = document.createElement('div');
      title.style.padding = '4px 10px';
      title.innerText = notif.msg;
      title.style.fontSize = '14px';

      if (notif.color == 'success') {
        title.style.color = '#10DC5F';
      }

      if (notif.color == 'warn' || notif.color == 'danger') {
        title.style.color = '#F34336';
      }

      toast.appendChild(title);
    }

    if (!notif.hideButtons) {
      const buttons = document.createElement('div');
      buttons.style.padding = '2px 4px';

      buttons.innerHTML = `<table class="w-100">
  <tr>      
    <td class="ion-text-end">
      <ion-button
        fill="outline"https://play.google.com/console/u/0/developers/7438070327128128830/app/4973625007533368038/inbox
        data-action="close"          
        >Закрити</ion-button
      >
    </td>
  </tr>
</table>`;
      toast.appendChild(buttons);
    }

    return toast;
  }

  private _handleToastNotifyEvent(event: any) {
    let target: HTMLElement = event.target;
    if (!target.dataset.action) {
      return;
    }

    let curerntEl: HTMLElement = event.target;

    let toast: HTMLElement;
    let i = 10;

    while (!toast && i > 0) {
      if (curerntEl.dataset.id) {
        toast = curerntEl;
        break;
      }

      curerntEl = curerntEl.parentElement;

      i--;
    }

    if (!toast) {
      return;
    }

    if (target.dataset.action == 'close') {
      this._closeToast(toast.dataset.id);
    }
  }

  private _playSound() {
    var audio = new Audio('assets/audio/new_order_sound.mp3');
    audio.play();
  }

  async infoDialog(
    title: string,
    text: string,
    buttons?: { label: string; color?: string; raised?: boolean }[],
    level = 'error'
  ) {
    const modal = await this.modalCtrl.create({
      component: DialogComponent,
      showBackdrop: true,
      componentProps: {
        title: title,
        message: text,
        buttons: buttons,
        level: level,
      },
      cssClass: 'dialog-modal',
    });
    modal.present();

    return modal.onDidDismiss().then((res) => {
      return res.data;
    });
  }

  async infoDialogNew(
    title: string,
    text: string,
    buttons?: {
      label: string;
      color?: string;
      raised?: boolean;
      action: string;
    }[],
    level = 'error'
  ) {
    const modal = await this.modalCtrl.create({
      component: DialogComponentNew,
      showBackdrop: true,
      componentProps: {
        title: title,
        message: text,
        buttons: buttons,
        level: level,
      },
      cssClass: 'dialog-modal',
    });
    modal.present();

    return modal.onDidDismiss().then((res) => {
      return res.data;
    });
  }

  async infoDialogMsg(title: string, messages: string[]) {
    const modal = await this.modalCtrl.create({
      component: DialogComponent,
      showBackdrop: true,
      componentProps: {
        messages: messages,
        title: title,
      },
      cssClass: 'dialog-modal',
      backdropDismiss: false,
    });
    modal.present();

    return modal.onDidDismiss().then((res) => {
      return res.data;
    });
  }

  toggleSidenav() {
    this.onToggleSidenav.emit();
  }

  notification = (message?: string) => {
    this._setNotification(message);
    setTimeout(() => this._removeNotification(), 1000);
  };

  notify = (message?: string, timeout = 5) => {
    this._setNotification(message);
    setTimeout(() => this._removeNotification(), timeout * 1000);
  };

  private _removeNotification() {
    const container = document.getElementById('notification');
    if (container) {
      container.parentNode.removeChild(container);
    }
  }

  private _setNotification(message: string): HTMLElement {
    let container = document.getElementById('notification');
    if (container) {
      const messageElement = container.querySelector('.message');
      messageElement.innerHTML = message;

      return container;
    }

    container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.right = '300px';
    container.style.bottom = '8px';
    container.style.backgroundColor = 'lightblue';
    container.style.borderRadius = '6px';
    container.id = 'notification';

    container.innerHTML = `<div class="py-1 px-3"><span class="mr-3 message">${message}</span></div>`;
    document.body.appendChild(container);
    return container;
  }

  closeNotify(timeout = 0) {
    setTimeout(() => this._removeNotification(), timeout * 1000);
  }

  async rangeCalendar(
    from?: Date,
    to?: Date
  ): Promise<{ start: Date; end: Date }> {
    const options: CalendarModalOptions = {
      pickMode: 'range',
      title: 'Оберіть період',
      canBackwardsSelected: true,
      defaultEndDateToStartDate: true,
      monthFormat: 'MMMM YYYY',
      weekdays: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      weekStart: 1,
      closeLabel: 'Закрити',
      doneLabel: 'Обрати',
    };

    if (from) {
      options.defaultDateRange = {
        from: from,
        to: to,
      };
    }

    let myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      backdropDismiss: false,
      componentProps: { options },
      cssClass: 'range-calendar-modal',
    });

    myCalendar.present();

    const { data, role } = await myCalendar.onDidDismiss();
    if (!data) {
      return;
    }

    return { start: data.from.dateObj, end: data.to.dateObj };
  }

  async _touchDatepicker(date: Date | null, timepicker = false): Promise<Date> {
    const componentProps: any = {
      fromDate: date,
      selected: DateUtils.format(date),
    };

    if (timepicker) {
      componentProps.presentation = 'date-time';
    }

    const modal = await this.modalCtrl.create({
      component: TouchDatepickerComponent,
      showBackdrop: true,
      componentProps: componentProps,
      backdropDismiss: false,
    });
    modal.present();

    return modal.onWillDismiss().then((res) => {
      return res.data;
    });
  }

  async touchDatepickerRange(
    fromDate: Date,
    toDate: Date,
    timepicker = false
  ): Promise<{ fromDate: Date; toDate: Date }> {
    const componentProps: any = {
      fromDate: fromDate,
      toDate: toDate,
      type: 'range',
    };

    if (timepicker) {
      componentProps.presentation = 'date-time';
    }

    const modal = await this.modalCtrl.create({
      component: TouchDatepickerComponent,
      showBackdrop: true,
      componentProps: componentProps,
      cssClass: 'datepicker-modal',
    });
    modal.present();

    return modal.onWillDismiss().then((res) => {
      return res.data;
    });
  }

  touchDatepicker(date: Date, timepicker = false): Promise<Date> {
    return this._touchDatepicker(date, timepicker) as Promise<Date>;
  }

  async selectModal(
    items: SelectModalItem[],
    header: string,
    columns: number | 'auto' = 'auto',
    hasDefaultOption = false
  ) {
    const _items = JSON.parse(JSON.stringify(items));
    if (hasDefaultOption) {
      _items.unshift({ label: 'Не обрано', value: null });
    }

    let _columns = 1;
    if (columns == 'auto') {
      _columns = _items.length > 10 ? 2 : 1;
    } else {
      _columns = columns;
    }

    const modal = await this.modalCtrl.create({
      component: SelectModalComponent,
      cssClass: 'select-modal',
      componentProps: { items: _items, header: header, columns: _columns },
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    return data;
  }

  async sendEmailDialog(checkUuid: string) {
    const modal = await this.modalCtrl.create({
      component: SendEmailDialogComponent,
      cssClass: 'send-email-modal',
      componentProps: { checkUuid: checkUuid },
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    return data;
  }

  sendEmail(sendDate: { email: string; checkUuid: string }) {
    return this.api.sendCheckEmail(sendDate);
  }

  showErrorToast(msg: string, duration = 3) {
    this.toastCtrl
      .create({
        position: 'top',
        color: 'danger',
        duration: duration * 1000,
        message: msg,
        buttons: [{ text: 'ОК' }],
        cssClass: 'toast-not',
      })
      .then((toast) => toast.present());
  }

  saveErrorToast() {
    this.toastCtrl
      .create({
        position: 'top',
        color: 'danger',
        duration: 4000,
        message: 'Помлка при збережені',
        buttons: [{ text: 'ОК' }],
        cssClass: 'toast-not',
      })
      .then((toast) => toast.present());
  }

  showSuccessToast(msg: string, duration = 2000) {
    this.toastCtrl
      .create({
        position: 'bottom',
        color: 'success',
        duration: duration,
        message: msg,
        buttons: ['OK'],
        cssClass: 'success-message',
      })
      .then((toast) => toast.present());
  }

  showWarnToast(msg: string, duration = 2000) {
    this.toastCtrl
      .create({
        position: 'bottom',
        color: 'warn',
        duration: duration,
        message: msg,
        buttons: ['OK'],
        cssClass: 'success-message',
      })
      .then((toast) => toast.present());
  }

  dismissTopToast() {
    this.toastCtrl.getTop().then((toast) => {
      if (toast) {
        this.toastCtrl.dismiss();
      }
    });
  }
}

@Component({
  selector: 'info-dialog',
  template: `<ion-content
    ><div class="dialog-wrapper h-100">
      <table class="w-100 h-100">
        <tr>
          <td colspan="2"><h2 [innerHTML]="title"></h2></td>
        </tr>
        <tr *ngIf="message">
          <td colspan="2" class="h-100">
            <div class="mb-4">
              <div>
                <ion-icon
                  *ngIf="icon"
                  class="mr-4"
                  style="font-size:36px;"
                  [style.color]="icon.color"
                  [name]="icon.icon"
                ></ion-icon>
                <span style="font-size: 20px">{{ message }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="message">
          <ng-template ngFor let-button [ngForOf]="buttons" let-i="index">
            <td [class.ion-text-right]="i > 0" class="btn-td">
              <ion-button
                fill="{{ button.raised ? 'solid' : 'outline' }}"
                color="{{ button.color || 'primary' }}"
                (click)="buttonClick(i)"
              >
                {{ button.label }}
              </ion-button>
            </td>
          </ng-template>
        </tr>
        <tr *ngIf="messages">
          <td colspan="2" class="h-100">
            <table class="w-100">
              <tr *ngFor="let msg of messages; let i = index">
                <td class="pr-3" style="vertical-align: baseline">
                  {{ i + 1 }}.
                </td>
                <td><span [innerHTML]="msg"></span></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr *ngIf="messages">
          <td colspan="2" class="h-100 ion-text-end">
            <ion-button color="dark" fill="outline" (click)="buttonClick(-1)">
              Закрити
            </ion-button>
          </td>
        </tr>
      </table>
    </div></ion-content
  >`,
  styles: [
    `
      ::ng-deep .dialog-modal {
        --min-height: 260px;
        --height: 280px;
        --width: 94%;
        --border-radius: 12px;
      }
      .dialog-wrapper {
        padding: 24px;
      }
      .btn-td {
        vertical-align: buttom;
      }

      @media only screen and (min-width: 768px) and (min-height: 768px) {
        ::ng-deep .dialog-modal {
          --width: 600px;
        }
      }
    `,
  ],
})
export class DialogComponent {
  buttons: any[];
  icon = {
    icon: 'information-circle-outline',
    color: 'red',
  };

  set level(l: string) {
    if (l === 'error')
      this.icon = {
        icon: 'information-circle-outline',
        color: 'red',
      };
    if (l === 'warning')
      this.icon = {
        icon: 'warning-outline',
        color: 'goldenrod',
      };
    if (l === 'info')
      this.icon = {
        icon: 'information-circle-outline',
        color: 'grey',
      };

    if (l === 'none') {
      this.icon = null;
    }
  }

  message: string;
  title: string;
  messages: string[];

  constructor(private _modalController: ModalController) {}

  buttonClick(btnIndex: number) {
    this._modalController.dismiss(btnIndex);
  }
}

@Component({
  selector: 'info-dialog',
  template: `<div class="dialog-wrapper h-100">
    <table class="w-100 h-100">
      <tr>
        <td colspan="2"><h2 [innerHTML]="title"></h2></td>
      </tr>
      <tr *ngIf="message">
        <td colspan="2" class="h-100">
          <div class="mb-4">
            <div>
              <ion-icon
                *ngIf="icon"
                class="mr-4"
                style="font-size:36px;"
                [style.color]="icon.color"
                [name]="icon.icon"
              ></ion-icon>
              <span style="font-size: 20px">{{ message }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="message">
        <ng-template ngFor let-button [ngForOf]="buttons" let-i="index">
          <td [class.ion-text-right]="i > 0" class="btn-td">
            <ion-button
              fill="{{ button.raised ? 'solid' : 'outline' }}"
              color="{{ button.color || 'primary' }}"
              (click)="buttonClick(button.action)"
            >
              {{ button.label }}
            </ion-button>
          </td>
        </ng-template>
      </tr>
      <tr *ngIf="messages">
        <td colspan="2" class="h-100">
          <table class="w-100">
            <tr *ngFor="let msg of messages; let i = index">
              <td class="pr-3" style="vertical-align: baseline">
                {{ i + 1 }}.
              </td>
              <td><span [innerHTML]="msg"></span></td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="messages">
        <td colspan="2" class="h-100 ion-text-end">
          <ion-button color="dark" fill="outline" (click)="buttonClick()">
            Закрити
          </ion-button>
        </td>
      </tr>
    </table>
  </div>`,
  styles: [
    `
      ::ng-deep .dialog-modal {
        --min-height: 260px;
        --height: 280px;
        --width: 94%;
        --border-radius: 12px;
      }
      .dialog-wrapper {
        padding: 24px;
      }
      .btn-td {
        vertical-align: buttom;
      }

      @media only screen and (min-width: 768px) and (min-height: 768px) {
        ::ng-deep .dialog-modal {
          --width: 600px;
        }
      }
    `,
  ],
})
export class DialogComponentNew {
  buttons: any[];
  icon = {
    icon: 'information-circle-outline',
    color: 'red',
  };

  set level(l: string) {
    if (l === 'error')
      this.icon = {
        icon: 'information-circle-outline',
        color: 'red',
      };
    if (l === 'warning')
      this.icon = {
        icon: 'warning-outline',
        color: 'goldenrod',
      };
    if (l === 'info')
      this.icon = {
        icon: 'information-circle-outline',
        color: 'grey',
      };

    if (l === 'none') {
      this.icon = null;
    }
  }

  message: string;
  title: string;
  messages: string[];

  constructor(private _modalController: ModalController) {}

  buttonClick(action?: string) {
    this._modalController.dismiss(action);
  }
}
